import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { ErrorService } from '../error/error.service';
import { PrintJobService } from '../print-job/print-job.service';
import { PrinterService } from '../printer/printer.service';
import { TenantService } from '../tenant/tenant.service';
import { IScanResource } from './model/IScanResource.model';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { IPagingInfo } from './model/IPagingInfo.model';

@Injectable({
  providedIn: 'root'
})
export class CaptureHistoryService {

  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
    private printerService: PrinterService,
    private printJobService: PrintJobService,
    private tenantService: TenantService,) {
    this.logger = loggingService.getLogger("[CaptureHistoryService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public getScanUrl(currentUserUrl: string): Observable<string> {
    this.logger.info('getScanUrl()');
    return new Observable<string>((observer: Subscriber<string>) => {
      this.httpClient.get<string>(currentUserUrl)
        .pipe(retryWhen(error => error.pipe(
          delay(1000),
          take(3),
          // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
          tap((httpErrorResponse: HttpErrorResponse) => { this.httpErrorResponse = httpErrorResponse }),
          finalize(() => {
            if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status === 403) {
              this.logger.info('getScanUrl() httpErrorResponse === ' + this.httpErrorResponse.status);
            } else {
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[CaptureHistoryService] getScanUrl()');
            }
            observer.error(this.httpErrorResponse);
            observer.complete();
          })
        )))
        .subscribe((tenantUser: any) => {
          // this.logger.info(JSON.stringify(tenantUser));
          let scanJobsUrl: string = tenantUser['_links']['px:scans']['href'];
          observer.next(scanJobsUrl);
          observer.complete();
        });
    });
  }

  public getScanResources(scansUrl: string, page: number, pageSize: number, sort?: string, direction?: string): Observable<object> {
    this.logger.info('getScans()');
    scansUrl += '?page=' + page + '&pageSize=' + pageSize;
    sort ? scansUrl += '&sort=' + sort : '';
    direction ? scansUrl += '&direction=' + direction : '';
    return new Observable<object>((observer: Subscriber<object>) => {
      this.httpClient.get<object>(scansUrl)
        .pipe(retryWhen(error => error.pipe(
          delay(1000),
          take(3),
          // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
          tap((httpErrorResponse: HttpErrorResponse) => { this.httpErrorResponse = httpErrorResponse }),
          finalize(() => {
            if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status === 403) {
              this.logger.info('getScanResources() httpErrorResponse === ' + this.httpErrorResponse.status);
            } else {
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[CaptureHistoryService] getScanResources()');
            }
            observer.error(this.httpErrorResponse);
            observer.complete();
          })
        )))
        .subscribe((rawRelease: object) => {
          observer.next(this.deserializeScanResources(rawRelease));
          observer.complete();
        });
    });
  }

  public deserializeScanResources(data): Array<IScanResource> {
    let scans: Array<IScanResource> = new Array<IScanResource>();
    scans["pagingObject"] = data["page"] as IPagingInfo;
    if (data["_embedded"]) {
      let scanDataArray: Array<any> = data["_embedded"]["px:scans"] as Array<any>;

      scanDataArray.forEach(scanData => {
        scans.push(this.deserializeScanResource(scanData));
      });
    }
    return scans;
  }

  public deserializeScanResource(scanData: any) {
    //WorkflowID and Error are optional depending on Bret's decision on adding this

    var destination: string = scanData["destinations"] ? scanData["destinations"][0] : "";
    var type: string;
    var workflowActivities: Array<any> = scanData["_embedded"]["workflowActivities"] as Array<any>;

    workflowActivities.forEach(activity => {
      if (activity["type"] === "GENERATE_DOCX" || activity["type"] === "GENERATE_SEARCHABLE_PDF") {
        type = activity["type"];
      }
    });

    let scanResource: IScanResource = {
      state: scanData["state"],
      created: scanData["created"],
      fileType: type,
      destinationType: destination,
      workflowName: scanData["cachedWorkflowName"]
    };
    return scanResource;
  }
}
