import { Injectable, OnInit } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform } from '@ionic/angular';
import { Observable, Subscriber } from 'rxjs';
import { ErrorService } from '../error/error.service';
import { PlatformService } from '../platform/platform.service';
import { Storage } from '@ionic/storage-angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { LocalStorageService } from 'angular-2-local-storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OnInit {

  private db: SQLiteObject = null;
  private isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  private mainDBName = 'data.mainDB';
  private tableNameKeyValue = 'printixapp_key_value';

  constructor(
    protected errorService: ErrorService,
    protected platform: Platform,
    private platformService: PlatformService,
    protected SQLite: SQLite,
    private localStorageService: LocalStorageService,
    private loggingService: LoggingService,
    private storage: Storage
    ) {
      this.logger = loggingService.getLogger("[StorageService]");
      const methodName = "ctor";
      this.logger.entry(methodName);
    }

    ngOnInit(): void {
    }

  protected logErrorMessage(subscriber: Subscriber<any>, error: any, calledFunction: string): void {
    this.logger.info('logErrorMessage()' + calledFunction + ' ERROR: ' + JSON.stringify(error));
    subscriber.error(error);
    subscriber.complete();
  }

  private openDB(): Observable<void> {
    return new Observable((observer) => {
      this.platform.ready().then(() => {
        if (this.db) {
          observer.next();
          observer.complete();
        } else {
          this.SQLite.create({
            name: this.mainDBName,
            location: 'default'
          }).then((SQLiteObject: SQLiteObject) => {
            this.db = SQLiteObject;
            this.db.executeSql('CREATE TABLE IF NOT EXISTS ' + this.tableNameKeyValue +
              ' (key TEXT PRIMARY KEY, value TEXT)', [])
              .then(() => {
                observer.next();
                observer.complete();
              }, (error: any) => {
                this.logErrorMessage(observer, error, 'openDB(), executeSql');
              });
          }, (error: any) => {
            this.logErrorMessage(observer, error, 'openDB(), create');
          });
        }
      });
    });
  }

  protected executeQuery(query: string, values: Array<any>): Observable<any> {
    return new Observable((observer) => {
      this.openDB().subscribe(() => {
        this.db.executeSql(query, values).then((data: any) => {
          if (data.rows.length > 0) {
            if (data.rows.length === 1) {
              observer.next(JSON.parse(data.rows.item(0).value));
              observer.complete();
            } else {
              let listToReturn: Array<any> = [];
              for (let i = 0; i < data.rows.length; i++) {
                listToReturn.push(JSON.parse(data.rows.item(i).value));
              }
              observer.next(listToReturn);
              observer.complete();
            }
          } else {
            observer.next();
            observer.complete();
          }
        }, (error: any) => {
          this.logErrorMessage(observer, error, 'executeQuery(), executeSql');
        });
      }, (error: any) => {
        this.logErrorMessage(observer, error, 'executeQuery(), openDB');
      });
    });
  }

  // protected insertMultipleValues(insertStatement, values: Array<any>): Observable<void> {
  //   return new Observable((observer) => {
  //     let batchList: Array<any> = [];
  //     values.forEach((value) => {
  //       batchList.push([insertStatement, value]);
  //     });
  //     this.openDB().subscribe(() => {
  //       this.db.sqlBatch(batchList).then((data: any) => {
  //         subscriber.next(data);
  //         subscriber.complete();
  //       }, (error: any) => {
  //         this.logErrorMessage(subscriber, error);
  //       });
  //     }, (error: any) => {
  //       this.logErrorMessage(subscriber, error);
  //     });
  //   });
  // }

  public addItemToKeyValueTable(key: string, value?: any, fromMethod?: string): Observable<void> {
    return new Observable((observer) => {
      if (this.isCordova) {  // set items in storage for devices
        value = JSON.stringify(value);
        let query: string = 'INSERT OR REPLACE INTO ' + this.tableNameKeyValue + ' (key, value) VALUES (?, ?)';
        let values: Array<any> = [key, value];
        this.executeQuery(query, values).subscribe(() => {
          observer.next();
          observer.complete();
        }, (error: any) => {
          this.logErrorMessage(observer, error, 'addItemToKeyValueTable(), executeQuery');
        });
      } else {  // set items in storage for browser
        let storedItem: string;
        if (key === 'lastUsedTenant') {
          this.logger.info('SAVING LastUsedTenant: ' + value);
          const serializedValue = JSON.stringify(value);
          this.localStorageService.set(key, serializedValue);
        } else {
          this.localStorageService.set(key, value);
        }
        observer.next();
        observer.complete();
      }
    });
  }

  public getItemFromKeyValueTable(key: string, fromMethod?: string): Observable<any> {
    return new Observable((observer) => {
      if (this.isCordova) { // get stored items for devices
        let query: string = 'SELECT value FROM ' + this.tableNameKeyValue + ' WHERE key=?';
        let values: Array<string> = [key];
        this.executeQuery(query, values).subscribe((data: any) => {
          observer.next(data);
          observer.complete();
        }, (error: any) => {
          this.logErrorMessage(observer, error, 'getItemFromKeyValueTable(), executeQuery');
        });
      } else { // get stored items for browser

        let storedItem: any;

        if (key === 'lastUsedTenant') {
          const stringifiedItem: any = this.localStorageService.get(key);
          storedItem = JSON.parse(stringifiedItem);
        } else {
          storedItem = this.localStorageService.get(key);
        }

        observer.next(storedItem);
        observer.complete();
      }
    });
  }

  public removeItemFromKeyValueTable(key: string): Observable<void> {
    return new Observable((observer) => {
      if (this.isCordova) {
        let query: string = 'DELETE FROM ' + this.tableNameKeyValue + ' WHERE key=?';
        let values: Array<string> = [key];
        this.executeQuery(query, values).subscribe(() => {
          observer.next();
          observer.complete();
        }, (error: any) => {
          this.logErrorMessage(observer, error, 'removeItemFromKeyValueTable(), executeQuery');
        });
      } else {
        this.localStorageService.remove(key);
        // this.storage.remove(key);
        observer.next();
        observer.complete();
      }
    });
  }
}
