import { PagingObject } from '../../paging/models/paging.model';

export class IPrinterListPagingObject extends PagingObject {

  public sort: string = 'SIGN_ID';
  public underCreation: Boolean = null;
  public networks: Array<string> = [];
  public global: string = null;
  public matchVendorModel: Boolean = null;
  public active: Boolean = null;
  public autoInstall: Boolean = null;
  public autoUpdate: Boolean = null;
  public defaultDuplex: Boolean = null;
  public defaultMono: Boolean = null;
  public printerTypes: Array<any> = [];
  public modelStartsWith: string = null;
  public wsCountFrom: number = null;
  public wsCountTo: number = null;
  public excludeQueues: Array<string> = [];
  public alwaysReprint: boolean = null;

  public embed: Array<string> = ['status'];
  public printers: Array<string> = [];
  public signIds: Array<string> = [];

  constructor() {
    super();
  }


  // public getPagingUrlString = function(): string {
  // let urlParameters =
  //     'page=' + this.page +
  //     "&pageSize=" + this.pageSize +
  //     "&direction=" + this.direction +
  //     "&sort=" + this.sort;
  //     if (this.networks) {
  //       urlParameters += "&networks=" + this.networks;
  //     }
  //     if (this.queuesFrom) {
  //       urlParameters += "&queuesFrom=" + this.queuesFrom;
  //     }
  //     if (this.queuesTo) {
  //       urlParameters += "&queuesTo=" + this.queuesTo;
  //     }
  //     if (this.createdFrom) {
  //       urlParameters += "&createdFrom=" + this.createdFrom;
  //     }
  //     if (this.match) {
  //       urlParameters += "&match=" + this.match;
  //     }
  //   return urlParameters;
  // }

}
