import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { CaptureUtils } from 'src/app/pages/capture/capture-files/capture-utils';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { IWorkflow, IWorkflowActivity } from './models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class CaptureProcessorService {

  private logger: Logger;
  private httpErrorResponse: HttpErrorResponse;
  public workflow: IWorkflow;
  public workflowList: Array<IWorkflow>;

  private imageprocessSuccessCB = null;
  private imageprocessErrorCB = null;

  //Alias name for kfxCordova.kfxEngine.ImageProcessor
  private ImageProcessor = null;

  //Reference variable for ImageProcessor Options
  private imageProcessorOptions = null;

  private captureImage = null;


  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private loggingService: LoggingService,
    private httpClient: HttpClient
  ) {
    this.logger = loggingService.getLogger("[CaptureProcessorService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
   }

	public getDefaultProcessorOptions = function () {
		if (!this.ImageProcessor) {
			this.ImageProcessor = window['kfxCordova'].kfxEngine.createImageProcessor();
		}
		return this.ImageProcessor.getImageProcessingOptions();
	};

	public doProcess = function (imageData, options, successCallback, errorCallback) {
		this.captureImage = imageData;
		this.imageProcessorOptions = options;
		console.log("IPP string::" + this.imageProcessorOptions.ImageProcessorConfiguration.ippString);
		this.addImageProcessorListeners();
		this.imageprocessSuccessCB = successCallback;
		this.imageprocessErrorCB = errorCallback;
	};

	public sendCallback = function (callback, data) {
		if (typeof callback === "function") {
			callback(data);
		}
	};

	/*
	 * Add all Image processing Listeners
	 *
	 */
	public addImageProcessorListeners = function () {
		/** This method would receive the on image processed at the ImageProcessor.
         imageOutHandler: The processed image is returned in this callback.
         */
         this.ImageProcessor.addImageOutEventListener(
			function (imageOutSuccess) {
				//Set the properties of the image processor
				this.setProcessorOptions();
				console.log("Image out listener registered success callback " + JSON.stringify(imageOutSuccess));
			},
			function (imageOutError) {
				this.sendCallback(this.imageprocessErrorCB, imageOutError);
				console.log("Image out listener error callback " + JSON.stringify(imageOutError));
			},
			this.imageOutHandler
		);

		/** This method would receive the Image process progress at the ImageProcessor.
        	processProgressHandler:Callback with a variable value representing Image processing progress will be returned.
         */
      this.ImageProcessor.addProcessProgressListener(
  			function (processProgressSuccess) {
  				console.log(
  					"Process progress listener registered success callback " + JSON.stringify(processProgressSuccess)
  				);
  			},
  			function (processProgressError) {
  				alert("Process progress listener error callback " + JSON.stringify(processProgressError));

  				console.log("Process progress listener error callback " + JSON.stringify(processProgressError));
  			},
  			this.processProgressHandler
		);
	};

	/*
	 * Removes All Image processing Listeners
	 *
	 */

	public removeImageProcessorListeners = function () {
		/** The method would remove the listener and would not receive the processed image events. After removing the listener,
        there will not be any call backs from native .
         */
      this.ImageProcessor.removeImageOutEventListener(
			function (imageOutSuccess) {
				console.log("Remove image out listener success callback " + JSON.stringify(imageOutSuccess));
			},
			function (imageOutError) {
				alert("Remove image out listener error callback " + JSON.stringify(imageOutError));

				console.log("Remove image out listener error callback " + JSON.stringify(imageOutError));
			}
		);

		/** The method would remove the listener and would not receive the Processed image progress events. After removing the listener,
        there will not be any call backs from native */

      this.ImageProcessor.removeProcessProgressListener(
			function (processProgressSuccess) {
				console.log(
					"Remove process progress listener success callback " + JSON.stringify(processProgressSuccess)
				);
			},
			function (processProgressError) {
				alert("Remove process progress listener error callback " + JSON.stringify(processProgressError));

				console.log("Remove process progress listener error callback " + JSON.stringify(processProgressError));
			}
		);
	};

	//Set the properties of the image processor
	public setProcessorOptions() {
		/** Set the Options/properties of the ImageProcessor.
		 * imageProcessorOptionsCB is a success callback.In this callback method will call the processImage.
		 * 'imageProcessorOptions'  variable containing the properties  to be set to the ImageProcessor.
		 */
     this.ImageProcessor.setImageProcessorOptions(
			this.imageProcessorOptionsCB,
			function (result) {
				this.sendCallback(this.imageprocessErrorCB, result);
			},
			this.imageProcessorOptions
		);
	}

	/*
	 * ImageProcessorOptions Success callback
	 *
	 */

	public imageProcessorOptionsCB = function (message) {
		/***************************************************
		 *
		 *
		 *
		 * ImageProcess method
		 *
		 *
		 *
		 * *************************************************
		 */

		/**
		 * This method when you want to perform standard image processing on the image id supplied with the method. The ImageProcessor
		 *	processes the image using the processing options contained in the profile you specified. You can specify either a basic
		 *	settings profile or an image perfection profile.
		 *  After processing the image, imageOutHandler("imageOutCallback") will get the processed image.
		 */

		this.ImageProcessor.processImage(
			function (result) {
				console.log("Success! processImage" + result);
			},
			function (result) {
				 this.sendCallback( this.imageprocessErrorCB, result);
				alert("Error! processImage " + JSON.stringify(result));
			},
			 this.captureImage.imgID
		);
	};

	/*
	 * Performs Quick Analysis on currently shown image in Image Review Edit Control
	 *
	 */

	public doQuickAnalysis = function (imageObject) {
		if (!this.ImageProcessor) {
			 this.ImageProcessor = window['kfxCordova'].kfxEngine.createImageProcessor();
		}
		/** This method would receive the Image Analysis complete at the ImageProcessor.
        	analysisCompleteHandler :Callback to hold the quickAnalysis result of the image.result will be in a JSON format as mentioned below.
        	Example : "QuickAnalysisFeedback":{"viewBoundariesImage":"","blurry":false,"overSaturated":false,"underSaturated":false,"BoundingTetragon":{"TopLeft":{"x":38,"y":639},"TopRight":{"x":1775,"y":639},"BottomLeft":{"x":38,"y":2910},"BottomRight":{"x":1775,"y":2910}}}
         */
		 this.ImageProcessor.addAnalysisCompleteListener(
			function (analysisSuccess) {
				this.startdoQuickAnalysis(imageObject);

				console.log(
					"Analysis complete listener registered success callback " + JSON.stringify(analysisSuccess)
				);
			},
			function (analysisError) {
				alert("Analysis complete listener error callback " + JSON.stringify(analysisError));

				console.log("Analysis complete listener error callback " + JSON.stringify(analysisError));
			},
			this.analysisCompleteHandler
		);
	};

	public startdoQuickAnalysis(imageObject) {
		//Set the doQuickAnalysis options
		var doQuickAnalysisOptions = {
			//Set the imageID of the image which will perform the quick analysis.
			imageID: imageObject.imgID,
			//If it is true, the viewBoundariesImage property of the QuickAnalysisFeedback object is updated with the preview Bitmap which shows the found page boundaries drawn in green.
			generateOutputImage: false,
			//A configuration Settings for controlling the quick analysis image processing.
			settings: null,
		};

		 this.ImageProcessor.doQuickAnalysis(
			function (result) {
				console.log("success doQuickAnalysis" + result);
			},
			function (result) {
				alert("Error! doQuickAnalysis" + JSON.stringify(result));
				this.removeQuickAnalysisCompleteListener();
			},
			doQuickAnalysisOptions
		);
	}

	/*
	 * QuickAnalysisComplete Success callback.
	 *  It will Shows the alert of quickAnalysis feedback.
	 */
	public analysisCompleteHandler = function (message) {
		//After analysis delete the image.
		delete message["image"];
		var INITIAL_TEXT = "The captured image is ";
		var result = JSON.stringify(message);
		console.log("Analysis complete listener registered success callback " + JSON.stringify(message));
		this.doQuickButtonIsPressed = false;

		/*Start: Prepare QuickAnalysisFeedback text */
		var analysis_txt = INITIAL_TEXT;

		if (message["QuickAnalysisFeedback"]["blurry"] == true) {
			analysis_txt += "blurred ";
		}
		if (message["QuickAnalysisFeedback"]["overSaturated"] == true) {
			if (analysis_txt.localeCompare(INITIAL_TEXT) != 0) {
				analysis_txt += "and";
			}
			analysis_txt += " over-saturated";
		} else if (message["QuickAnalysisFeedback"]["underSaturated"] == true) {
			if (analysis_txt.localeCompare(INITIAL_TEXT) != 0) {
				analysis_txt += "and";
			}
			analysis_txt += " under-saturated";
		}
		if (analysis_txt.localeCompare(INITIAL_TEXT) != 0) {
			alert(analysis_txt);
		}

		this.removeQuickAnalysisCompleteListener();
		/*End: Prepare QuickAnalysisFeedback text */
	};

	public removeQuickAnalysisCompleteListener() {
		/** The method would remove the listener and would not receive the Analysis complete events. After removing the listener,
        	there will not be any call backs from native.
         */
		 this.ImageProcessor.removeAnalysisCompleteListener(
			function (analysisSuccess) {
				console.log("Remove analysis complete listener success callback " + JSON.stringify(analysisSuccess));
			},
			function (analysisError) {
				alert("Remove analysis complete listener error callback " + JSON.stringify(analysisError));

				console.log("Remove analysis complete listener error callback " + JSON.stringify(analysisError));
			}
		);
	}

	/*
	 * will be invoked, once image processing gets completed
	 *
	 * @gets processed image imageId
	 *
	 */
	public imageOutHandler = function (ImgObject) {
		this.removeImageProcessorListeners();
		 this.sendCallback(this.imageprocessSuccessCB, ImgObject);
	};

	/*
	 * logs image processing percentage
	 *
	 * @gets image processing percentage
	 *
	 */
	public processProgressHandler(message) {
		console.log("calling processProgressHandler from app.js ProgressPercent :: " + message.ProgressPercent);
	};
}
