<ion-app>
  <div class="ipad-spinner" *ngIf="showIpadSpinner"></div>
  <!-- <app-header
  [iconName]="'menu'"
  [nav]="nav"
  [title]="null"
  [menuToggleRight]="true"
  [showErrorMessageNetwork]="true"
  [showErrorMessageHealth]="true"
  [allowHeartDisplay]="true">
</app-header> -->
<app-menu [content]="content"></app-menu>
<ion-router-outlet id="menuContent"></ion-router-outlet>
</ion-app>