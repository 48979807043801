import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  public isCordova: boolean = true;

  constructor(
    private platform: Platform
  ){
    this.isCordova = this.platform.is('hybrid');
  }
}