<ion-menu
  tappable
  [content]="content"
  contentId="menuContent"
  side="end"
  persistent="true"
  swipe-gesture="false"
  type="overlay"
  auto-hide="true"
  (ionDidClose)="menuOnClose()">
  <ion-header class="menu-header">
    <ion-toolbar color="transparent">
      <ion-menu-button slot="end">
        <i class="icon-menu-toggle cl-grey"></i>
      </ion-menu-button>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <div *ngIf="isCordova" class="version-number-container">
      <span class="version-number">V.{{appVersion}}</span>
    </div>
    <div class="error-network" *ngIf="!isInternetAccessAvailable">
      <span class="error-network-container">
        <i class="icon-warning"></i>
        <span>{{"NoAccessInternet" | translate}}</span>
      </span>
    </div>
    <ion-list class="menu-list">
      <ion-item class="menu-item" lines="none"
        tappable
        [menuClose]="menuItem.languageMenuToggle"
        *ngFor="let menuItem of menuItems"
        (click)="menuItemClick($event, menuItem)"
        [hidden]="!showMenuItem(menuItem)">
        <div class="menu-item-button" color="contrast" [ngClass]="{'menu-item-active': isComponentActive(menuItem)}">
          <i class="{{menuItem.icon}}"></i>
          <span padding-left class="size90 ml10">{{menuItem.title | translate}}</span>
          <div class="language-items" menuClose *ngIf="menuItem.languageMenuToggle" [hidden]="!showLanguageMenu">
            <ion-item lines="none" tappable *ngFor="let languageItem of languageItems" (click)="languageItemClick(languageItem)">
              <ion-button color="contrast" block large clear icon-start [ngClass]="{'menu-item-active': isLanguageActive(languageItem)}">
                <!-- <span class="flag-icon flag-icon-{{languageItem.codeFlag}}"></span> -->
                <span padding-left class="size70">{{languageItem.nameNative}}</span>
              </ion-button>
            </ion-item>
          </div>
        </div>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>
