import { Injectable } from '@angular/core';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import { NavController, Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TenantSelectPage } from 'src/app/pages/auth/tenant-select/tenant-select.page';
import { GoPage } from 'src/app/pages/go/go.page';
import { PrintJobsPage } from 'src/app/pages/print-jobs/print-jobs.page';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../dialog/dialog.service';
import { Broadcaster } from '../events/broadcaster.class';
import { IntuneMamService } from '../intune-mam/intune-mam.service';
import { LogoutService } from '../logout/logout.service';
import { PlatformService } from '../platform/platform.service';
import { StorageService } from '../storage/storage.service';
import { ITenantLite } from '../tenant/models/tenant-lite.model';
import { TenantService } from '../tenant/tenant.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  public  authWaiting: boolean = false;
  public  errorMessage: string = null;
  private isCordova: boolean = this.platformService.isCordova;
  public  loginFailed: string = null;
  private logger: Logger;
  public  recoveringPassword: boolean = false;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private logoutService: LogoutService,
    private loggingService: LoggingService,
    public navController: NavController,
    private platformService: PlatformService,
    private platform: Platform,
    private safariViewController: SafariViewController,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userService: UserService,
    private intuneMamService: IntuneMamService
  ) {
    this.logger = loggingService.getLogger("[LoginService]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.setListeners();
  }

  private setListeners(): void {
    this.platform.ready().then(() => {
      if (this.isCordova) {

      }
    });
  }

  public handleOpenUrl(url: string, userInputTenantDomain?: string) {
    this.logger.info('handleOpenUrl() Url: ' + url.split("&code=")[0]);
    this.logger.info('handleOpenUrl() userInputTenantDomain: ' + userInputTenantDomain);

    if (url.includes('code=')) {
      if (this.isCordova) {
        // console.log('%c** YO **', 'color: green; font-size: 20px');
      }
      this.authService.login(this.extractCodeFromUrl(url))
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(() => {
        if (this.isCordova) {
          this.safariViewController.hide();
        } else {
          let params = location.search;
          let href = location.href.split('?')[0];
          // console.log('***** LoginService - L81 - params', params);
          if (params.includes('registrationCode')) {
            if (params.includes('&')) {
              let splitParams = params.split('&');
              for (let i = 0; i < splitParams.length; i++) {
                if (splitParams[i].includes('registrationCode')) {
                  href = href + splitParams[i];
                  location.href = href;
                }
              }
            }
          } else {
            location.href = href;
          }
        }
        this.authSuccessful(userInputTenantDomain);
      }, (error: any) => {
        this.logger.info('handleOpenUrl() authService.login() ERROR: ' + JSON.stringify(error));
        if (this.isCordova) {
          this.safariViewController.hide();
        }
        this.authWaiting = false;
      });
    }

    if (url.startsWith('printixapp://?error_code=')) {
      if (this.isCordova) {
        this.safariViewController.hide();
      }
      this.recoveringPassword = true;
      this.loginFailed = 'Something went wrong, please resend email';
    }
  }

  public authSuccessful(userInputTenantDomain?: string) {
    // continue the login process
    this.logger.info('authSuccessful() userInputTenantDomain: ' + userInputTenantDomain);
    this.broadcaster.broadcast('ENABLE_RESUME');
    this.getLastUsedTenant(userInputTenantDomain);
  }

  private extractCodeFromUrl(url): string {
    this.logger.info('extractCodeFromUrl()');
    let test = url.split('?')[1].split('&').find(x => x.indexOf(this.authService.urlParamCode) > -1).replace(this.authService.urlParamCode, '');
    return url.split('?')[1].split('&').find(x => x.indexOf(this.authService.urlParamCode) > -1).replace(this.authService.urlParamCode, '');
  }

  public getLastUsedTenant(userInputTenantDomain: string): void { // user is logged in
    this.logger.info('getLastUsedTenant()');
    this.storageService.getItemFromKeyValueTable('lastUsedTenant', 'getLastUsedTenant()') // get last used tenant
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((lastUsedTenant: ITenantLite) => {
      if (lastUsedTenant) {
        this.logger.info('got lastUsedTenant hostingDomain: ' + lastUsedTenant['tenantData']['hostingDomain']);
        this.logger.info('got lastUsedTenant Url: ' + lastUsedTenant['links']['self']);
      } else {
        this.logger.info('no lastUsedTenant');
      }
      this.getTenantListForUser(userInputTenantDomain, lastUsedTenant);
    }, (error) => {
      this.logger.info('error getting lastUsedTenant: ' + error);
      this.startLogOut();
    });
  }

  private getTenantListForUser(userInputTenantDomain: string, lastUsedTenant: ITenantLite) {
    console.log('## USER_INPUT_TENANT_NAME ##');
    console.log('userInputTenantName', userInputTenantDomain);
    this.logger.info('getTenantListForUser()');
    this.tenantService.getTenantListForUser() // get tenant list
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((tenantList: Array<ITenantLite>) => {
      this.logger.info('got TenantList with length: ' + tenantList.length);
      this.broadcaster.broadcast('tenantList', tenantList); // broadcast tenant list length for menu item 'change home'
      if (userInputTenantDomain) {
        let userTenant = this.findTenantFromHostingDomain(userInputTenantDomain, tenantList);
        if (userTenant) {
          this.completeLoginProcess(userTenant);
        } else {
          this.alertNoMatchingTenantInTenantList(userInputTenantDomain);
        }
      } else {
        this.handleTenantSelection(tenantList, lastUsedTenant);
      }
    }, () => {
        this.logger.info('authSuccessful() no tenantList');
        if (!this.isCordova) {
          // location.reload(); // disabled to avoid recursive page reloads
        }
        // this.dialogService.showAlertDialog('LoginService.getLastUsedTenant: No tennantList found');
    });
  }

  private findTenantFromHostingDomain(tenantHostingDomain: string, tenantList: Array<ITenantLite>): ITenantLite {
    this.logger.info('findTenantFromHostingDomain()');
    let userTenant: ITenantLite;
      tenantList.forEach(tenant => {
        if (tenant.tenantData.hostingDomain === tenantHostingDomain) {
          userTenant = tenant;
        }
      });
      return userTenant;
  }

  private handleTenantSelection(tenantList: Array<ITenantLite>, lastUsedTenant: ITenantLite): void {
    this.logger.info('handleTenantSelection()');
    let selectedTenant: ITenantLite = lastUsedTenant;
    if (this.isCordova && !lastUsedTenant && tenantList.length === 1) { // On cordova platform set tenant if only one in the list, to omit tenant selection for one tenant
      selectedTenant = tenantList[0];
    }
    if (selectedTenant) { // if there is only one, or a last used tenant, go directly to printJobs page
      this.completeLoginProcess(selectedTenant);
    } else {
      let urlTenantDomain: string = location.href.split('/')[2];
      // let urlTenantDomain: string = 'printix70.printix.net';
      if (!this.isCordova && !urlTenantDomain.includes('localhost')) { // on browser, if you are logged in, find the tenant in the list from the url, in order not to have to select tenant from a list
      // if (!this.isCordova) { // on browser, if you are logged in, find the tenant in the list from the url, in order not to have to select tenant from a list
        selectedTenant = this.findTenantFromHostingDomain(urlTenantDomain, tenantList);
        if (selectedTenant) {
          this.completeLoginProcess(selectedTenant);
        } else {
          this.alertNoMatchingTenantInTenantList(urlTenantDomain);
        }
      } else {
        // this.storageService.removeItemFromKeyValueTable
        this.dialogService.hideLoadingSpinnerDialog('LoginService - handleTenantSelection()');
        this.setLoginNavigation('/tenant-select');
      }
    }
  }

  private alertNoMatchingTenantInTenantList(tenantHostingDomain) {
    this.logger.info('No matching hosting domain for Tenant url: ' + tenantHostingDomain);
    this.dialogService.hideLoadingSpinnerDialog('LoginService - alertNoMatchingTenantInTenantList()');
    this.dialogService.showAlertDialog('No matching hosting domain for Tenant url, if you are a rw-user you cannot access the release app: ' + tenantHostingDomain);
  }

  public completeLoginProcess(tenant: ITenantLite): void {
    this.logger.info('completeLoginProcess()');

    this.tenantService.refreshCurrentTenant(tenant.links.self) // tenant and user are refreshed to show current state of things
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((refreshedTenant: ITenantLite) => {
      if (this.tenantService.tenant) {
        this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((user) => {
          this.storageService.addItemToKeyValueTable('lastUsedTenant', refreshedTenant, 'completeLoginProcess()')
          .subscribe(() => {
            // console.log('**** LoginService - L229 - location.href', location.href);
            if (location.href.includes('registrationCode')) {
              let params = ''
              // this.broadcaster.broadcast('restartService');
              let splitHref = location.href.split('?');
              for (let i = 0; i < splitHref.length; i++) {
                if (splitHref[i].includes('registrationCode')) {
                  params =  splitHref[i];
                }
              }
              // console.log('**** params ****', params);
              this.setLoginNavigation('/go?' + params);
            } else {
              this.checkForIntunePolicy(user); // before going to printjobs page, check if Intune MAM policy is set by customer
            }
          });
        }, () => {
          this.logger.info('completeLoginProcess(): No tenant after refreshCurrentTenant');
          this.startLogOut();
        });
      } else {
        this.startLogOut();
      }
    }, () => {
      const currentTenant = tenant ? tenant.tenantData.name : null;
        this.logger.info('completeLoginProcess(): efresh current tenant: ' + currentTenant + ' failed');
        this.startLogOut();
    });
  }

  private checkForIntunePolicy(user) {
    // if (true) { // tenant.tenantData.intune // checking for Intune flag, to determine if user needs to verify against Intune MAM policy
    //   const userEmail = user.embedded ? user.embedded.user.email : null;
    //   if (userEmail) {
    //     this.intuneMamService.loginAndEnrollIntune(userEmail)
    //     .takeUntil(this.unSubscribe)
    //     .subscribe((response) => {
    //       this.setLoginNavigation(PrintJobsPage);
    //     });
    //   } else {
    //     this.dialogService.showAlertDialog('Cannot connect to Intune MAM, missing userEmail: ' + userEmail);
    //   }
    // } else {
    //   this.setLoginNavigation(PrintJobsPage);
    // }
    this.setLoginNavigation('/print-jobs');
  }

  private setLoginNavigation(route: string): void {
    this.logger.info('setLoginNavigation()');
    this.navController.navigateRoot(route);
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  public startLogOut() {
    this.logger.info('startLogOut()');
    this.logoutService.logOut();
  }
}