import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { BehaviorSubject, Observable, Observer, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';
import { ErrorService } from '../error/error.service';
import { ITenantLite } from './models/tenant-lite.model';
import { ITenantData } from './models/tenant.data.model';
import { ITenant } from './models/tenant.model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private patchObserver: Observer<ITenantData> = null;
  public currentTenant: BehaviorSubject<ITenant> = new BehaviorSubject<ITenant>(null);

  public tenant: ITenant = null;
  public tenantList: Array<ITenantLite> = null;
  public tenantListAuthenticated: boolean = false;
  public cloudStorageStatus: boolean = false;
  private networksListPageObject: any;
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  private endpointGetTenantUser: string;
  private endpointFindTenant: string;
  private environmentService: EnvironmentService;

  constructor(
    private errorService: ErrorService,
    private injector: Injector,
    private loggingService: LoggingService,
    private httpClient: HttpClient
  ) {
    this.logger = loggingService.getLogger("[TenantService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  private changesListenerStart(): void {

    // this.patchObserver = jsonPatch.observe(this.tenant.tenantData, null);
  }

  private changesListenerStop(): void {
    if (this.patchObserver && this.tenant) {
      // jsonPatch.unobserve<ITenantData>(this.tenant.tenantData, this.patchObserver);
    }
  }

  private deserializeLinkObjects(tenant: ITenant, links: any): void {
    if (links) {
      if (links['self'] && links['self'].href) {
        tenant.links.self = links['self'].href;
      }
      if (links['px:currentuser'] && links['px:currentuser'].href) {
        tenant.links.currentUser = links['px:currentuser'].href;
      }
      if (links['px:printers'] && links['px:printers'].href) {
        tenant.links.printers = links['px:printers'].href;
      }
      if (links['px:queues'] && links['px:queues'].href) {
        tenant.links.queues = links['px:queues'].href;
      }
      if (links['px:commandReleasePrintJob'] && links['px:commandReleasePrintJob'].href) {
        tenant.links.releasePrintJob = links['px:commandReleasePrintJob'].href;
      }
      if (links['px:commandDeletePrintJob'] && links['px:commandDeletePrintJob']) {
        tenant.links.deletePrintJob = links['px:commandDeletePrintJob'].href;
      }
      if (links['px:networks'] && links['px:networks'].href) {
        tenant.links.networks = links['px:networks'].href;
      }
      if (links['px:workstations'] && links['px:workstations'].href) {
        tenant.links.workstations = links['px:workstations'].href;
      }
      if (links['px:workflows'] && links['px:workflows'].href) {
        tenant.links.workflows = links['px:workflows'].href;
      }
      if (links['px:airprintPasswords'] && links['px:airprintPasswords'].href) {
        tenant.links.airPrintPasswords = links['px:airprintPasswords'].href;
      }
      if (links['px:findPrinterByNfcTag'] && links['px:findPrinterByNfcTag'].href) {
        tenant.links.findPrinterByNfcTag = links['px:findPrinterByNfcTag'].href;
      }
      if (links['px:deviceLogUploads'] && links['px:deviceLogUploads'].href) {
        tenant.links.deviceLogUploads = links['px:deviceLogUploads'].href;
      }
      if (links['px:idCodes'] && links['px:idCodes'].href) {
        tenant.links.idCodes = links['px:idCodes'].href;
      }
      if (links['px:userDevices'] && links['px:userDevices'].href) {
        tenant.links.userDevices = links['px:userDevices'].href;
      }

    }
  }

  private deserializeEmbeddedObjects(tenant: ITenant, embedded: any): void {
    if (embedded) {
      for (let key in embedded) {
        tenant.embedded[key] = embedded[key];
      }
    }
  }

  private deserializeTenant(input: any, skipObservers: boolean): ITenant {
    this.changesListenerStop();
    if (input.hostingDomains) {
      let tenant: ITenant = {
        links: {
          airPrintPasswords: null,
          currentUser: null,
          deletePrintJob: null,
          deviceLogUploads: null,
          findPrinterByNfcTag: null,
          idCodes: null,
          networks: null,
          printers: null,
          queues: null,
          releasePrintJob: null,
          self: null,
          userDevices: null,
          workflows: null,
          workstations: null
        },
        embedded: {
          PUSHER_CLIENT_INFO: input._embedded.PUSHER_CLIENT_INFO ? input._embedded.PUSHER_CLIENT_INFO : null,
          authenticationConfiguration: input._embedded.authenticationConfiguration ? input._embedded.authenticationConfiguration : null,
          productsAndFeatures: input._embedded.productsAndFeatures ? input._embedded.productsAndFeatures : null,
          securePrintConfiguration: input._embedded.securePrintConfiguration ? input._embedded.securePrintConfiguration : null
        },
        tenantData: {
          mobilePrint: input.mobilePrint ? input.mobilePrint : false,
          // cloudStorageEnabled: this.cloudStorageStatus ? this.cloudStorageStatus : false,
          hostingDomain: input.hostingDomains[0] ? input.hostingDomains[0] : null,
          name: input.name ? input.name : null
        }
      };
      if (input._links) {this.deserializeLinkObjects(tenant, input._links);}
      if (!skipObservers) {
        this.changesListenerStart();
      }
      return tenant;
    }
  }

  public refreshCurrentTenant(tenantLink: string): Observable<any> {
    this.logger.info('refreshCurrentTenant()');
    const embed: string = '?embed=PUSHER_CLIENT_INFO,securePrintConfiguration';
    return new Observable((observer) => {
      this.httpClient.get<any>(tenantLink + embed)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('refreshCurrentTenant() httpErrorResponse === ' + this.httpErrorResponse.status);
            observer.error();
            observer.complete();
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[TenantService] refreshCurrentTenant()');
            observer.error();
            observer.complete();
          }
        })
      )))
      .subscribe((res: any) => {
        // console.log('res', res);
        this.tenant = this.deserializeTenant(res, false);
        // this.currentTenant.next(this.tenant);
        observer.next(this.tenant);
        observer.complete();
      });
    });
  }

  public get freshTenant(): Observable<ITenant> {
    return this.currentTenant.asObservable();
  }

  /** TENANTLIST for multipe tenant select */
  public getTenantListForUser(): Observable<Array<ITenantLite>> {
    this.logger.info('getTenantListForUser()');
    if (!this.environmentService) {
      this.environmentService = this.injector.get(EnvironmentService);
    }
    this.endpointGetTenantUser = this.environmentService.getEnvironmentEndpoint['endpointGetTenantUser'];
    return new Observable((observer) => {
      this.httpClient.get<any>(this.endpointGetTenantUser)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getTenantListForUser() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[TenantService] getTenantListForUser()');
          }
          observer.error();
          observer.complete();
        })
      )))
      .subscribe((res: any) => {
        this.tenantListAuthenticated = true;
        observer.next(this.deserializeTenantList(res));
        observer.complete();
      });
    });
  }

  private deserializeTenantList(tenantsRaw: any): Array<ITenantLite> {
    let tenantList: Array<ITenantLite> = [];
    for (let tenant of tenantsRaw._embedded.tenants) {
      let tenantLite: ITenantLite = {
        links: {
          self: tenant._links['px:tenant'].href,
          currentUser: tenant._links['px:tenant-user'].href,
        },
        tenantData: {
          name: tenant.name,
          hostingDomain: tenant.hostingDomain,
          intune: tenant.intune ? tenant.intune : null
        }
      };
      tenantList.push(tenantLite);
    }
    return tenantList;
  }

  public getTenantNetworks(netWorksEndpoint: string, storedNetworks: Array<string>, page: number, pageSize: number, sort: string, direction: string, searchString: string, airPrint: boolean): Observable<Array<any>> {
    this.logger.info('getTenantNetworks()');
    return new Observable((observer) => {
      let getNetworksUrl = netWorksEndpoint + '?page=' + page + '&pageSize=' + pageSize + '&sort=' + sort + '&direction=' + direction;

      if (searchString) {
        getNetworksUrl = getNetworksUrl + '&match=' + searchString;
      }

      if (storedNetworks.length > 0) {
        storedNetworks.forEach(nw => {
          getNetworksUrl += '&networkIds=' + nw;
        });
      }

      this.httpClient.get<any>(getNetworksUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getTenantNetworks() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[TenantService] getTenantNetworks()');
          }
          observer.error();
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        this.networksListPageObject = {
          page: response.page
        };
        observer.next(this.deserializeTenantNetworks(response));
        observer.complete();
      });
    });
  }

  private deserializeTenantNetworks(input: any): Array<any> {
    let tenantNetworksList: Array<any> = [];

    if (input._embedded) {
      for (let network of input._embedded['px:networkResources']) {
        tenantNetworksList.push(this.deserializeNetwork(network));
      }
      tenantNetworksList.push(this.networksListPageObject);
    }

    return tenantNetworksList;
  }

  private deserializeNetwork(input: any): any {
    let network: any = {
      links: {
        self: input._links.self.href,
      },
      name: input.name
    };
    return network;
  }

  public resetTenant(): void {
    this.logger.info('resetTenant()');
    this.tenant = null;
  }

  public checkIfUserTenantDomainExists(tenantDomain): Observable<any> {
    this.logger.info('checkIfUserTenantDomainExists()');
    if (!this.environmentService) {
      this.environmentService = this.injector.get(EnvironmentService);
    }
    this.endpointFindTenant = this.environmentService.getEnvironmentEndpoint['endpointFindTenant'];
    const url = this.endpointFindTenant + tenantDomain;
    return new Observable((observer) => {
      this.httpClient.get<any>(url)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('checkIfUserTenantDomainExists() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else if (this.httpErrorResponse.status === 404) {
            this.logger.info('checkIfUserTenantDomainExists() httpErrorResponse: 404 - Printix home does not exist');
          } else if (this.httpErrorResponse.status ===  410) {
            this.logger.info('checkIfUserTenantDomainExists() httpErrorResponse: 410 - Printix home no longer exist ');
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[TenantService] checkIfUserTenantDomainExists()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(response);
        observer.complete();
      });
    });
  }

  // public getWorkflows(workflowsUrl): Observable<any> {
  //   return new Observable((observer) => {
  //     this.httpClient.get<any>(workflowsUrl)
  //     .pipe(retryWhen(error => error.pipe(
  //       delay(1000),
  //       take(3),
  //       // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
  //       tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
  //       finalize(() => {
  //         if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
  //           this.logger.info('checkIfUserTenantDomainExists() httpErrorResponse === ' + this.httpErrorResponse.status);
  //         } else {
  //           this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[TenantService] checkIfUserTenantDomainExists()');
  //         }
  //         observer.error(this.httpErrorResponse);
  //         observer.complete();
  //       })
  //     )))
  //     .subscribe((response: any) => {
  //       observer.next(response);
  //       observer.complete();
  //     });
  //   });
  // }
}