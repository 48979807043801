import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultHeadersInterceptor implements HttpInterceptor {

  private PATCH: string = 'patch';
  private headerAcceptKey: string = 'Accept';
  private headerAcceptValue: string = 'application/hal+json, application/json';
  private headerContentTypeKey: string = 'Content-Type';
  private headerContentTypeValueJson: string = 'application/json';
  private headerContentTypeValueJsonPatch: string = 'application/json-patch+json';

  constructor() {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newHttpRequest: HttpRequest<any> = httpRequest.clone();
    if (!newHttpRequest.headers.has(this.headerAcceptKey)) {
      newHttpRequest = newHttpRequest.clone({ headers: newHttpRequest.headers.set(this.headerAcceptKey, this.headerAcceptValue) });
    }
    if (!newHttpRequest.headers.has(this.headerContentTypeKey)) {
      if (newHttpRequest.method.toLowerCase() === this.PATCH.toLowerCase()) {
        newHttpRequest = newHttpRequest.clone({ headers: newHttpRequest.headers.set(this.headerContentTypeKey, this.headerContentTypeValueJsonPatch) });
      } else {
        newHttpRequest = newHttpRequest.clone({ headers: newHttpRequest.headers.set(this.headerContentTypeKey, this.headerContentTypeValueJson) });
      }
    }
    return next.handle(newHttpRequest);
  }
}
