import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector, OnInit } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { EnvironmentService } from '../environment/environment.service';
import { ErrorService } from '../error/error.service';
import { Broadcaster } from '../events/broadcaster.class';
import { PlatformService } from '../platform/platform.service';
import { StorageService } from '../storage/storage.service';
import { ITokens } from './models/tokens.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnInit {

  private accessTokenRefreshTimeBufferInSeconds: number = 30;
  private isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  public  storageKeyTokenAccess: string = 'AuthAccessToken';
  public  storageKeyTokenRefresh: string = 'AuthRefreshToken';
  private storageKeyTokenAccessExpiresAt: string = 'AuthTokenTimeout';
  public  urlParamCode: string = 'code=';
  private httpErrorResponse: HttpErrorResponse;

  private appLink: string;
  private endPointAuthorization: string;
  private endpointCodeExchange: string;
  private endpointLogOut: string;
  private environmentService: EnvironmentService;

  constructor(
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private injector: Injector,
    private loggingService: LoggingService,
    private platformService: PlatformService,
    private storageService: StorageService
  ) {
    this.logger = loggingService.getLogger("[AuthService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  ngOnInit(): void {
  }

  public removeStorageData(): Observable<void> {
    this.logger.info('removeStorageData()');
    return new Observable<void>((subscriber: Subscriber<void>) => {
      // console.log('## AuthService removeStorageData() REMOVE refreshToken');
      this.storageService.removeItemFromKeyValueTable(this.storageKeyTokenRefresh).subscribe(() => {
      // console.log('## AuthService removeStorageData() REMOVE accessToken');
        this.storageService.removeItemFromKeyValueTable(this.storageKeyTokenAccess).subscribe(() => {
      // console.log('## AuthService removeStorageData() REMOVE expireToken');
          this.storageService.removeItemFromKeyValueTable(this.storageKeyTokenAccessExpiresAt).subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
        });
      });
    });
  }

  public setStorageData(tokenRefresh: string, tokenAccess: string, tokenAccessTimeoutInSeconds: number): Observable<void> {
    this.logger.info('setStoreageData()');
    return new Observable<void>((subscriber: Subscriber<void>) => {
      // console.log('## AuthService setStorageData() SET refreshToken');
      this.storageService.addItemToKeyValueTable(this.storageKeyTokenRefresh, tokenRefresh, 'setStorageData()').subscribe(() => {
        // console.log('## AuthService setStorageData() SET accessToken');
        this.storageService.addItemToKeyValueTable(this.storageKeyTokenAccess, tokenAccess, 'setStorageData()').subscribe(() => {
          let tokenAccessExpiresAt: number = new Date().getTime() + ((tokenAccessTimeoutInSeconds - this.accessTokenRefreshTimeBufferInSeconds) * 1000);
          // console.log('## AuthService setStorageData() SET expireToken');
          this.storageService.addItemToKeyValueTable(this.storageKeyTokenAccessExpiresAt, tokenAccessExpiresAt, 'setStorageData()').subscribe(() => {

            /////////////////////////////////////////////////////////////////////////
            // this.storageService.getItemFromKeyValueTable(this.storageKeyTokenAccess)
            // .subscribe((tokenAccess: string) => {
            //   console.log('tokenAccess', tokenAccess);
            // });
            /////////////////////////////////////////////////////////////////////////

            subscriber.next();
            subscriber.complete();
          });
        });
      });
    });
  }

  public verifyUserCredentials(): Observable<void> {
    this.logger.info('verifyUserCredentials() get refreshToken from storage');
    return new Observable<void>((subscriber: Subscriber<void>) => {
      // console.log('## AuthService verifyUserCredentials() GET refreshToken');
      this.storageService.getItemFromKeyValueTable(this.storageKeyTokenRefresh, 'verifyUserCredentials()').subscribe((tokenRefresh: string) => {
        if (tokenRefresh) {
          subscriber.next();
          subscriber.complete();
        } else {
          // console.log('## NO FREFRESH TOKEN');
          this.logger.info('verifyUserCredentials(): No stored refreshToken');
          subscriber.error();
          subscriber.complete();
        }
      });
    });
  }

  private checkIfTokenIsUsable(url): Observable<boolean> {
    this.logger.info('checkIfTokenIsUsable() for url: ' + url);
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      // console.log('## AuthService checkIfTokenIsUsable() GET expireToken');
      this.storageService.getItemFromKeyValueTable(this.storageKeyTokenAccessExpiresAt, 'checkIfTokenIsUsable').subscribe((tokenAccessExpiresAt: number) => {
        if (tokenAccessExpiresAt) {
          // console.log('tokenAccessExpiresAt', tokenAccessExpiresAt);
          if (new Date().getTime() < tokenAccessExpiresAt) {
            subscriber.next(true);
            subscriber.complete();
          } else {
            subscriber.next(false);
            subscriber.complete();
          }
        } else {
          // log error
          subscriber.error();
          subscriber.complete();
        }
      });
    });
  }

  private loginExistingUser(code: string): Observable<void> {
    this.logger.info('loginExistingUser()');
    this.logger.info('loginExistingUser() code.length: ' + code.length);

    if (!this.environmentService) {
      this.environmentService = this.injector.get(EnvironmentService);
    }
    this.appLink = this.environmentService.getEnvironmentEndpoint['appLink'];
    this.endPointAuthorization = this.environmentService.getEnvironmentEndpoint['endPointAuthorization'];
    this.endpointCodeExchange = this.environmentService.getEnvironmentEndpoint['endpointCodeExchange'];

    return new Observable((observer) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      });
      let returnDomain: string = this.isCordova ? encodeURIComponent(this.appLink) : location.origin;
      let dataToSend: string = this.endPointAuthorization + returnDomain + '&code=' + code;
      this.httpClient.post<ITokens>(this.endpointCodeExchange, dataToSend, { headers: headers })
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('loginExistingUser() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[AuthService] loginExistingUser()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((tokens: ITokens) => {
        this.setStorageData(tokens.refresh_token, tokens.access_token, tokens.expires_in).subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    });
  }

  private startRefreshingTokenAccess(): Observable<string> {
    this.logger.info('### startRefreshingTokenAccess()');
    return new Observable((observer) => {
      // console.log('## AuthService startRefreshingTokenAccess() GET refreshToken');
      // this.dialogService.showLoadingSpinnerDialog('AuthService - startRefreshingTokenAccess()');
      this.storageService.getItemFromKeyValueTable(this.storageKeyTokenRefresh, 'startRefreshingTokenAccess()').subscribe((tokenRefresh: string) => {
        if (tokenRefresh) {
          if (!this.environmentService) {
            this.environmentService = this.injector.get(EnvironmentService);
          }
          this.endpointCodeExchange = this.environmentService.getEnvironmentEndpoint['endpointCodeExchange'];
          const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          });
          let dataToSend: string = "grant_type=refresh_token&client_id=web_app&client_secret=thehouseisonfire&refresh_token=" + tokenRefresh;
          this.httpClient.post<ITokens>(this.endpointCodeExchange, dataToSend, { headers: headers })
          .pipe(retryWhen(error => error.pipe(
            delay(1000),
            take(1),
            // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
            tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
            finalize(() => {
              // this.dialogService.hideLoadingSpinnerDialog('AuthService - startRefreshingTokenAccess() - getItemFromKeyValueTable()');
              if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
                this.logger.info('startRefreshingTokenAccess() httpErrorResponse === ' + this.httpErrorResponse.status);
                this.logOutUnAuthenticatedUser('ACCESS_DENIED'); // logout user and go to login page
              }
              observer.error();
              observer.complete();
            })
          )))
          .subscribe((tokens: ITokens) => {
            // this.dialogService.hideLoadingSpinnerDialog('AuthService - startRefreshingTokenAccess() - getItemFromKeyValueTable()');
            this.setStorageData(tokens.refresh_token, tokens.access_token, tokens.expires_in).subscribe(() => {
              // console.log('## AuthService startRefreshingTokenAccess() GET accessToken');
              this.storageService.getItemFromKeyValueTable(this.storageKeyTokenAccess, 'startRefreshingTokenAccess()').subscribe((accessToken: string) => {
                observer.next(accessToken);
                observer.complete();
              });
            });
          });
        } else {
          this.dialogService.hideLoadingSpinnerDialog('AuthService - startRefreshingTokenAccess() - getItemFromKeyValueTable() - !tokenRefresh');
          const tokenLength = tokenRefresh ? tokenRefresh.length : '0';
          this.logger.info('startRefreshingTokenAccess(): tokenRefresh is missing');
          this.logger.info('startRefreshingTokenAccess(): tokenRefresh.length: ' + tokenLength);
          observer.next();
          observer.complete();
          this.logOutUnAuthenticatedUser('ACCESS_DENIED');
        }
      });
    });
  }

  private getAccessToken(url?): Observable<string> {
    this.logger.info('getAccessToken()');
    return new Observable((observer) => {
      this.storageService.getItemFromKeyValueTable(this.storageKeyTokenAccess, 'getAccessToken()').subscribe((accessToken: string) => {
        if (accessToken) {
          this.checkIfTokenIsUsable(url).subscribe((isTokenUsable: boolean) => {
            this.logger.info('getAccessToken() isTokenUsable: ' + isTokenUsable);
            if (isTokenUsable) {
              observer.next(accessToken);
              observer.complete();
            } else {
              this.logger.error('#### REFRESHING_TOKEN ######')
              this.startRefreshingTokenAccess().subscribe((accessToken: string) => {
                observer.next(accessToken);
                observer.complete();
              });
            }
          }, (error) => {
            this.logger.info('Error on checkIfTokenIsUsable inside getAccessToken()');
            observer.complete();
          });
        } else {
          this.logger.info('getAccessToken() Missing accessToken');
          this.startRefreshingTokenAccess().subscribe((accessToken: string) => {
            observer.next(accessToken);
            observer.complete();
          });
        }
      });
    });
  }

  private startDeAuthenticationProcess(): Observable<void> {
    this.logger.info('startDeAuthenticationProcess()');

    return new Observable((observer) => {
      // console.log('## AuthService startDeAuthenticationProcess() GET refreshToken');
      this.storageService.getItemFromKeyValueTable(this.storageKeyTokenRefresh, 'startDeAuthenticationProcess()').subscribe((tokenRefresh: string) => {
        if (tokenRefresh) {
          if (!this.environmentService) {
            this.environmentService = this.injector.get(EnvironmentService);
          }
          this.endpointLogOut = this.environmentService.getEnvironmentEndpoint['endpointLogOut'];
          const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
          });
          this.httpClient.get<void>(this.endpointLogOut + tokenRefresh, { headers: headers })
          .pipe(retryWhen(error => error.pipe(
            delay(1000),
            take(3),
            // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
            tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
            finalize(() => {
              this.dialogService.hideLoadingSpinnerDialog('AuthService - startDeAuthenticationProcess() - getItemFromKeyValueTable() - Error').then(() => {
                if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
                  this.logger.info('startDeAuthenticationProcess() httpErrorResponse === ' + this.httpErrorResponse.status);
                } else {
                  this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[AuthService] startDeAuthenticationProcess()');
                }
                observer.error(this.httpErrorResponse);
                observer.complete();
              });
            })
          )))
          .subscribe(() => {
            this.dialogService.hideLoadingSpinnerDialog('AuthService - startDeAuthenticationProcess() - getItemFromKeyValueTable()').then(() => {
              this.removeStorageData().subscribe(() => {
                observer.next();
                observer.complete();
              });
            });
          });
        } else {
          this.dialogService.hideLoadingSpinnerDialog('AuthService - startDeAuthenticationProcess() - getItemFromKeyValueTable() - !tokenRefresh').then(() => {
            this.removeStorageData().subscribe(() => {
              observer.next();
              observer.complete();
            });
          });
        }
      });
    });
  }

  public refreshAccessTokenFromUrl(tokenRefresh: string): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    let dataToSend: string = "grant_type=refresh_token&client_id=web_app&client_secret=thehouseisonfire&refresh_token=" + tokenRefresh;
    if (!this.environmentService) {
      this.environmentService = this.injector.get(EnvironmentService);
    }
    this.endpointCodeExchange = this.environmentService.getEnvironmentEndpoint['endpointCodeExchange'];
    return new Observable((observer) => {
      this.httpClient.post<ITokens>(this.endpointCodeExchange, dataToSend, { headers: headers })
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          observer.complete();
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('refreshAccessTokenFromUrl() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[AuthService] startRefreshingTokenAccess()');
          }
        })
      )))
      .subscribe((tokens: ITokens) => {
        this.setStorageData(tokens.refresh_token, tokens.access_token, tokens.expires_in).subscribe(() => {
          // console.log('## AuthService refreshAccessTokenFromUrl() GET accessToken');
          this.storageService.getItemFromKeyValueTable(this.storageKeyTokenAccess, 'refreshAccessTokenFromUrl()').subscribe((accessToken: string) => {
            observer.next(accessToken);
            observer.complete();
          });
        });
      });
    });
  }

  public logOutUnAuthenticatedUser(logOutMessage) {
    this.logger.info('logOutUnAuthenticatedUser()');
    let navParams: {} = {};
    navParams['URL_PARAM_ERROR'] = logOutMessage;
    navParams['URL_PARAM_LOGOUT'] = false;
    navParams['URL_PARAM_UNAUTHENTICATED_USER'] = true;
    this.broadcaster.broadcast('logOutUser', navParams);
  }

  public tokenGetter(url?): Observable<string> {
    return this.getAccessToken(url);
  }

  public refreshAccessToken(): Observable<string> {
    return this.startRefreshingTokenAccess();
  }

  public login(code: string): Observable<void> {
    return this.loginExistingUser(code);
  }

  public deAuthenticateUser(): Observable<void> {
    return this.startDeAuthenticationProcess();
  }
}