export const logging_config_dev = {
    "logLevels": [
        {
        "loggerName": "root",
        "logLevel": "INFO"
        }
    ],
    "localStorageAppender": {
        "localStorageKey": "deviceLogs",
        "maxMessages": 2000,
        "threshold": "INFO"
    },
    "browserConsoleAppender": {
        // "threshold": "OFF"
        "threshold": "DEBUG"
    }
}