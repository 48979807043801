<ion-content>
  <div class="error-network" *ngIf="!isInternetAccessAvailable">
    <span class="error-network-container">
      <i class="icon-warning"></i>
      <span>{{"NoAccessInternet" | translate}}</span>
    </span>
  </div>
  <div *ngIf="showEulaText">
    <div class="eula-text" [innerHTML]="eulaHtml"></div>
    <hr>
    <section>
      <div class="eula-buttons">
        <ion-button class="eula-deny" (click)="eulaDenied()">{{"Reject" | translate}}</ion-button>
        <ion-button class="eula-accept" (click)="eulaAccepted()">{{"Accept" | translate}}</ion-button>
      </div>
    </section>
  </div>

  <div *ngIf="showUnsupportedVersionMessage">
    <!-- <ion-item class="title">
      <ion-label>{{ 'UpdateRequired' | translate }}</ion-label>
    </ion-item>
    <ion-item tapable class="go-to-store-link" *ngIf="platform.is('android')">
      <img src="./assets/market-links/google-play-badge.png" alt="google-play-store-link" (click)="goToStore()">
    </ion-item>
    <ion-item tapable class="go-to-store-link" *ngIf="platform.is('ios')">
      <img src="./assets/market-links/apple-app-store-badge.png" alt="apple-app-store-link" (click)="goToStore()">
    </ion-item> -->
  </div>
  </ion-content>