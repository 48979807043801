import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { IPrinter } from '../printer/models/printer.model';
import { IQueue } from '../printer/models/queue.model';

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  private logger: Logger;

  constructor(
    private loggingService: LoggingService,
  )
  {
    this.logger = loggingService.getLogger("");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  // ################# PRINT_JOBS_PAGE ##################################################################################### //

  public logUserAppSettings(userAppSettings: object, pageComponent: string) {
    const settingsObject = {
      lastUsedNetwork: userAppSettings['lastUsedNetwork'] ? userAppSettings['lastUsedNetwork'] : null,
      lastUsedPrinter: userAppSettings['lastUsedPrinter'] ? userAppSettings['lastUsedPrinter'] : null,
      lastUsedQueue: userAppSettings['lastUsedQueue'] ? userAppSettings['lastUsedQueue'] : null,
      favoriteQueueUrls: userAppSettings['pinnedPrinterQueues'] ? userAppSettings['pinnedPrinterQueues'] : [],
      securePrintMethods: userAppSettings['securePrintMethods'] ? userAppSettings['securePrintMethods'] : []
    };
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + '## UserAppSettings ##');
    this.logger.info(componentName + 'lastUsedNetwork: ' + settingsObject['lastUsedNetwork']);
    this.logger.info(componentName + 'lastUsedPrinter: ' + settingsObject['lastUsedPrinter']);
    this.logger.info(componentName + 'lastUsedQueue: ' + settingsObject['lastUsedQueue']);
    this.logger.info(componentName + 'favoriteQueueUrlsCount: ' + settingsObject['favoriteQueueUrls'].length);
    this.logger.info(componentName + 'securePrintMethods: ' + settingsObject['securePrintMethods']);
  }

  public logLastUsedPrinter(lastUsedPrinter: IPrinter, pageComponent: string) {
    const printerObject = {
      name: lastUsedPrinter['name'] ? lastUsedPrinter['name'] : null,
      printerUrl: lastUsedPrinter['links']['self'] ? lastUsedPrinter['links']['self'] : null,
      signId: lastUsedPrinter['signId'] ? lastUsedPrinter['signId'] : null,
      queues: lastUsedPrinter['queues'] ? lastUsedPrinter['queues'] : []
    };
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + 'got lastUsedPrinter');
    this.logger.info(componentName + 'lastUsedPrinter-name: ' + printerObject['name']);
    this.logger.info(componentName + 'lastUsedPrinter-ID: ' + printerObject['printerUrl']);
    this.logger.info(componentName + 'lastUsedPrinter-SignID: ' + printerObject['signId']);
    this.logger.info(componentName + 'lastUsedPrinter-QueuesAmount: ' + printerObject['queues'].length);
  }

  public logReleaseItems(selectedPrinter: IPrinter, selectedQueue: IQueue, printJobUrls: Array<string>, releaseResourceId: string, pageComponent: string) {
    const releaseItems: any = {
      printer: selectedPrinter ? selectedPrinter.links.self : null,
      queue: selectedQueue ? selectedQueue.links.self : null,
      printJobs: printJobUrls,
      releaseResourceID: releaseResourceId
    };
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + 'logReleaseItems()');
    this.logger.info(componentName + 'releaseSelectedPrintJobs() printerID: ' + releaseItems['printer']);
    this.logger.info(componentName + 'releaseSelectedPrintJobs() printerQueue: ' + releaseItems['queue']);
    this.logger.info(componentName + 'releaseSelectedPrintJobs() releaseSourceID: ' + releaseItems['releaseResourceID']);
    this.logger.info(componentName + 'releaseSelectedPrintJobs() printJobs.length: ' + releaseItems['printJobs'].length);
  }

  public logScannedPrinter(scannedPrinter: object, pageComponent: string) {
    const printerObject = {
      name: scannedPrinter['name'] ? scannedPrinter['name'] : null,
      signId: scannedPrinter['signId'] ? scannedPrinter['signId'] : null,
      printerUrl: scannedPrinter['links']['self'] ? scannedPrinter['links']['self'] : null
    };
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + '## SCANNED_PRINTER ##');
    this.logger.info(componentName + '## NAME:    ' + printerObject['name']);
    this.logger.info(componentName + '## SIGN_ID: ' + printerObject['signId']);
    this.logger.info(componentName + '## URL:     ' + printerObject['printerUrl']);
  }

  public logAvailableQueues(queuesToSelect: Array<IQueue>, pageComponent: string) {
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + 'logAvailableQueues()');
    queuesToSelect.forEach((q, index) => {
      this.logger.info(componentName + 'queuesToSelect: queue_#' + index);
      this.logger.info(componentName + '##### name' + q['name']);
    });
  }

  public logAvailableResources(availableResource, pageComponent) {
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + 'logAvailableResources()');
    availableResource['printers'].forEach((resourcePrinter, index) => {
      this.logger.info(componentName + 'availableResources: ' + 'printer_#' + index);
      this.logger.info(componentName + '######## SignID: ' + resourcePrinter['signId']);
      this.logger.info(componentName + '######## Name: ' + resourcePrinter['name']);
      this.logger.info(componentName + '######## Amount of queues: ' + resourcePrinter['embedded']['queues'].length);
      this.logger.info(componentName + '######## URL: ' + resourcePrinter['links']['self']);
    });
  }

  public logUpdatedReleaseItemsBeforeReleaseStart(releaseItems: object, pageComponent: string) {
    const componentName = '[' + pageComponent + '] ';
    this.logger.info(componentName + 'updateReleaseResourceBeforeRelease() updatePrintJobsOnRelease() - printer url on release resource: ' + releaseItems['printerQueue']['selectedPrinter']['links']['self']);
    this.logger.info(componentName + 'updateReleaseResourceBeforeRelease() updatePrintJobsOnRelease() - printer signId on release resource: ' + releaseItems['printerQueue']['selectedPrinter']['signId']);
    this.logger.info(componentName + 'updateReleaseResourceBeforeRelease() updatePrintJobsOnRelease() - queue url on release resource: ' + releaseItems['printerQueue']['selectedQueue']['links']['self']);
    this.logger.info(componentName + 'updatePrintJobsOnRelease() - ReleaseID: ' + releaseItems['resourceId']);
  }

  // ################# /PRINT_JOBS_PAGE ##################################################################################### //


  // ################# MODAL_COMPONENT ##################################################################################### //

  public logPrinterInfo(printer) {
    this.logger.info('[ModalComponent] updatedPrinter: ' + printer['links']['self']);
    this.logger.info('[ModalComponent] updatedPrinter name: ' + printer['name']);
    this.logger.info('[ModalComponent] updatedPrinter signId: ' + printer['signId']);
    this.logger.info('[ModalComponent] updatedPrinter queues.length: ' + printer['embedded']['queues'].length);
    this.logger.info('[ModalComponent] updatedPrinter pollStatus: ' + printer['embedded']['status']['pollStatus']);
    printer['embedded']['status']['errors'].forEach(error => {
      this.logger.info('[ModalComponent] updatedPrinter Error: ' + error);
    });
    printer['embedded']['status']['warnings'].forEach(warning => {
      this.logger.info('[ModalComponent] updatedPrinter Warning: ' + warning);
    });
  }

  // ################# /MODAL_COMPONENT ##################################################################################### //


  // ################# SECURE_PRINT_SERVICE ##################################################################################### //

  public logSecuritySettings(securePrintConfiguration) {
    this.logger.info('[SecurePrintService] mustPrintSecurely: ' + securePrintConfiguration['mustPrintSecurely']);
    this.logger.info('[SecurePrintService] minimumSecurityLevel: ' + securePrintConfiguration['minimumSecurityLevel']);
    this.logger.info('[SecurePrintService] securityLevelNONE: ' + securePrintConfiguration['_embedded']['securityLevelNONE']);
    this.logger.info('[SecurePrintService] securityLevelQR: ' + securePrintConfiguration['_embedded']['securityLevelQR']);
    this.logger.info('[SecurePrintService] securityLevelNFC: ' + securePrintConfiguration['_embedded']['securityLevelNFC']);
    this.logger.info('[SecurePrintService] securityLevelGO: ' + securePrintConfiguration['_embedded']['securityLevelGO']);
    this.logger.info('[SecurePrintService] mustPrintSecurelyGroup: ' + securePrintConfiguration['_embedded']['mustPrintSecurelyGroup']);
  }

  // ################# /SECURE_PRINT_SERVICE ##################################################################################### //


  // ################# ERROR_SERVICE ##################################################################################### //

  public logErrorData(httpErrorResponse, requestMethod, calledFunction) {
    const errorURL = httpErrorResponse['url'] ? httpErrorResponse['url'] : '';
    const errorCode = httpErrorResponse['error'] && httpErrorResponse['error']['errorCode'] ? httpErrorResponse['error']['errorCode'] : '';
    const errorText = httpErrorResponse['error'] && httpErrorResponse['error']['errorText'] ? httpErrorResponse['error']['errorText'] : '';
    const errorMessage = httpErrorResponse['message'] ? httpErrorResponse['message'] : '';
    const errorStatusText = httpErrorResponse['statusText'] ? httpErrorResponse['statusText'] : '';
    const errorRequestID = httpErrorResponse.headers && httpErrorResponse.headers.get('x-printix-request-id') ? httpErrorResponse.headers.get('x-printix-request-id') : '';
    this.logger.error('[ErrorService] ERROR: handleHttpClientResponseError() from: ' + calledFunction);
    this.logger.error('[ErrorService] logErrorData()-METHOD: ' + requestMethod);
    this.logger.error('[ErrorService] logErrorData()-ERROR_CODE: ' + errorCode);
    this.logger.error('[ErrorService] logErrorData()-ERROR_TEXT: ' + errorText);
    this.logger.error('[ErrorService] logErrorData()-MESSAGE: ' + errorMessage);
    this.logger.error('[ErrorService] logErrorData()-URL: ' + errorURL);
    this.logger.error('[ErrorService] logErrorData()-STATUS: ' + httpErrorResponse['status']);
    this.logger.error('[ErrorService] logErrorData()-REQUEST_ID: ' + errorRequestID);
  }

  // ################# /ERROR_SERVICE ##################################################################################### //
}