import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subject, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, takeUntil, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { PagingService } from '../paging/paging.service';
import { IPrintJob } from '../print-job/models/print-job.model';
import { IPrinter } from '../printer/models/printer.model';
import { IQueue } from '../printer/models/queue.model';
import { PrinterService } from '../printer/printer.service';
import { ReleaseResourceService } from '../release-resource/release-resource.service';
import { StringularService } from '../../pipes/stringular.pipe';
import { ITenant } from '../tenant/models/tenant.model';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class ReleasePrintService {

  private unSubscribe: Subject<void> = new Subject<void>();
  private printerParameters: any;
  private printerUrlString: string;
  private selectedPrinter: IPrinter = null;
  private tenant: ITenant = null;
  private scanning: boolean = false;
  public releaseResource: any = null;
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private pagingService: PagingService,
    private loggingService: LoggingService,
    private printerService: PrinterService,
    public releaseResourceService: ReleaseResourceService,
    private stringularService: StringularService,
    private toastController: ToastController,
    private translateService: TranslateService,
    public userService: UserService,
  ) {
    this.logger = loggingService.getLogger("[ReleasePrintService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

 public releaseSelectedPrintJobs(selectedPrinter: IPrinter, selectedPrintJobs: Array<IPrintJob>, selectedQueue: IQueue, startReleaseUrl: string): Observable<Array<IPrintJob>> {
  this.logger.info('releaseSelectedPrintJobs()');
  if (selectedPrinter) {
    if (selectedPrintJobs && selectedPrintJobs.length > 0) {
      return new Observable((observer) => {
        this.httpClient.post(startReleaseUrl, {})
        .pipe(retryWhen(error => error.pipe(
          delay(1000),
          take(3),
          // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
          tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
          finalize(() => {
              if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
                this.logger.info('releaseSelectedPrintJobs() httpErrorResponse === ' + this.httpErrorResponse.status);
              } else {
                this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[ReleasePrintService] releaseSelectedPrintJobs()');
              }
              observer.error(this.httpErrorResponse);
              observer.complete();
          })
          )))
          .subscribe((response: any) => {
            this.logger.info('releaseSelectedPrintJobs() - release started without errors');
            let message: string;  // toast message for ui
            let selectedJobCount = selectedPrintJobs.length;
            if (selectedJobCount > 1) {
              let selectedjobCountString: string = selectedJobCount.toString();
              message = this.translateMessageWithParameter('PrintDocs', [selectedjobCountString]);
            } else if (selectedJobCount === 1) {
              message = this.translateMessageWithParameter('PrintDoc');
            }
            this.showToastForReleasePrint(message);
            if (selectedQueue !== null) {
              this.logger.info('releaseSelectedPrintJobs() - setting lastUsedPrinter: ' + selectedPrinter.links.self);
              this.userService.setLastUsedPrinter(selectedPrinter) // set last used printer
              .pipe(takeUntil(this.unSubscribe))
              .subscribe(() => {
                this.logger.info('releaseSelectedPrintJobs() - setting lastUsedQueue: ' + selectedQueue.links.self);
                this.userService.setLastUsedQueue(selectedQueue)
                .pipe(takeUntil(this.unSubscribe))
                .subscribe(() => {
                  this.unSubscribe.next();
                  this.unSubscribe.complete();
                  observer.next(response);
                  observer.complete();
                });
              });
            } else {
              this.unSubscribe.next(response);
              this.unSubscribe.complete();
              observer.next(response);
              observer.complete();
            }
        });
      });
    } else {
      throw "PrintJobListService.releaseSelectedPrintJobs: no selected print jobs";
    }
  } else {
    throw "PrintJobListService.releaseSelectedPrintJobs: printer is missing or it has errors";
  }
}

 private translateMessageWithParameter(text: string, args?: string[]): string {
  this.logger.info('translateMessageWithParameter()');
  if (text) {
    this.translateService.get(text)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((value: string) => {
      text = value;
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
    if (args) {
      text = this.stringularService.transform(text, ...args);
    }
    return text;
  }
}

private showToastForReleasePrint(message): void {
  this.logger.info('showToastForReleasePrint()');
  let toast = this.toastController.create({
    message: message,
    duration: 3000,
    position: 'top'
  }).then(toast => toast.present());
}
}
