// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment_prod = {
  production: true,
  appType: 'IONIC_APP',
  appTitle: 'Printix App',
  appLink: 'printixapp://',
  appLogo: 'assets/img/logo/logo-img.png',
  mainDBName: 'data.mainDB',
  tableNameKeyValue: 'printixapp_key_value',
  endpointLogInResponse_WepApp: '?response_type=code&client_id=web_app&redirect_uri=https://',
  endpointLogInResponseForgotCode_WepApp: '&response_type=code&client_id=web_app&redirect_uri=https://',
  endpointLogInResponse_Native: '?response_type=code&client_id=web_app&redirect_uri=',
  endPointAuthorization: 'grant_type=authorization_code&client_id=web_app&client_secret=1234&redirect_uri=',

   // --------------------------- UnActive Endpoints ---------------------------------------------------- //
    
    // logoUrl: '/assets/img/logo/logo-img.png',
    // navParamLogOut: 'logout',
    // enumerationUrl: 'https://api.printix.net/v1/enumerations/PrinterType',
  
  /********************************************************************************************************************** */
  /** PROD */
  /********************************************************************************************************************** */
  appEndpoint: 'https://app.printix.net',
  checkServerHealth: 'https://api.printix.net/manage/health',
  internalTestWaitTime: 'https://api.printix.net/internal/testing/wait/',
  internalTestReturnSetStatusCode: 'https://api.printix.net/internal/testing/status/',
  endpointApi: 'https://api.printix.net',
  endpointLogInWepAppTenant: 'https://auth.printix.net/oauth/authorize/tenant/',
  endpointLogInMS: 'https://auth.printix.net/identity-providers/azure/signin?redirect_uri=',
  endpointLogInGoogle: 'https://auth.printix.net/identity-providers/google/signin?redirect_uri=',
  endpointCodeExchange: 'https://auth.printix.net/oauth/token',
  endpointLogOut: 'https://auth.printix.net/oauth/revoke?token=',
  endpointGetTenantUser: 'https://api.printix.net/v1/users/me',
  endpointFindTenant: 'https://api.printix.net/v1.2/find/',
  environmentState: 'production' 
  
  // --------------------------- UnActive Endpoints ---------------------------------------------------- //
  
  // endpointTenants: 'https://api.printix.net/v1/tenants/',
  // endPointTenantHub: 'sign-in.printix.net/tenanthub',
  // endpointLogIn: 'https://auth.printix.net/oauth/authorize?response_type=code&client_id=hibrid_app&redirect_uri=',
  // endpointToken: 'https://auth.printix.net/oauth/token',
  // endpointError: 'https://api.printix.net',
  // endpointRecoverPassword: 'https://auth.printix.net/recover/access',
  // endpointLogInVertical: 'https://auth.printix.net/identity-providers/vertical/signin?redirect_uri=', 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.