import { LoginPage } from 'src/app/pages/auth/login/login.page';
import { NetworkCheckPage } from 'src/app/pages/auth/network-check/network-check.page';
import { TenantSelectPage } from 'src/app/pages/auth/tenant-select/tenant-select.page';
import { CapturePage } from 'src/app/pages/capture/capture.page';
import { GoPage } from 'src/app/pages/go/go.page';
import { PrintJobsPage } from 'src/app/pages/print-jobs/print-jobs.page';
import { PrinterListPage } from 'src/app/pages/printer-list/printer-list.page';
import { RegisterNfcTagPage } from 'src/app/pages/register-nfc-tag/register-nfc-tag.page';
import { ReleaseHistoryPage } from 'src/app/pages/release-history/release-history.page';
import { CaptureHistoryPage } from 'src/app/pages/capture-history/capture-history.page';
import { IMenuItem } from './menu-item.model';

export const MenuItems: Array<IMenuItem> = [
  {
    title: 'SignIn',
    component: LoginPage,
    route: '/sign-in',
    navParams: {},
    icon: 'icon-sign-in',
    languageMenuToggle: false,
    showIfAuthenticated: false,
    showIfNotAuthenticated: true,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Print',
    component: PrintJobsPage,
    route: '/print-jobs',
    navParams: {},
    icon: 'icon-print',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Printers',
    component: PrinterListPage,
    route: '/printer-list',
    navParams: {},
    icon: 'icon-printer',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Capture',
    component: CapturePage,
    route: '/capture',
    navParams: {},
    icon: 'icon-capture',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Recents',
    component: PrintJobsPage,
    route: '/recents',
    navParams: {},
    icon: 'icon-clock',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'PrintHistory',
    component: ReleaseHistoryPage,
    route: '/release-history',
    navParams: {},
    icon: 'icon-history',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'CaptureHistory',
    component: CaptureHistoryPage,
    route: '/capture-history',
    navParams: {},
    icon: 'icon-history',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true,
  },
  {
    title: 'CardsAndCodes',
    component: GoPage,
    route: '/go',
    navParams: {},
    icon: 'icon-access-card-2',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'ChangeHome',
    component: TenantSelectPage,
    route: '/tenant-select',
    navParams: { clearLastUsedTenant: false },
    icon: 'icon-home',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true,
  },
  {
    title: 'PrinterIdMethod',
    component: null,
    route: null,
    navParams: {},
    icon: 'icon-secure-print-sm',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true,
  },
  {
    title: 'RegisterNfcTag',
    component: RegisterNfcTagPage,
    route: '/register-nfc-tag',
    navParams: {},
    icon: 'icon-nfc-chip',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true,
  },
  {
    title: 'AirPrintPassword',
    component: null,
    route: null,
    navParams: {},
    icon: 'icon-lock',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Diagnostics',
    component: null,
    route: null,
    navParams: {},
    icon: 'icon-diagnostics',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: true,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Language',
    component: null,
    route: null,
    navParams: {},
    icon: 'icon-language',
    languageMenuToggle: true,
    showIfAuthenticated: true,
    showIfNotAuthenticated: true,
    showIfInternetIsAvailable: true
  },
  {
    title: 'Help',
    component: null,
    route: null,
    navParams: '{{"UrlUserManual" | translate}}',
    icon: 'icon-help',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  },
  {
    title: 'SignOut',
    component: NetworkCheckPage,
    route: '/network-check',
    navParams: { URL_PARAM_LOGOUT: true, URL_PARAM_UNAUTHENTICATED_USER: false, URL_PARAM_ERROR: '' },
    icon: 'icon-sign-out',
    languageMenuToggle: false,
    showIfAuthenticated: true,
    showIfNotAuthenticated: false,
    showIfInternetIsAvailable: true
  }
];