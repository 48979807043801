import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginPage } from 'src/app/pages/auth/login/login.page';
import { AuthService } from '../auth/auth.service';
import { DialogService } from '../dialog/dialog.service';
import { EnvironmentService } from '../environment/environment.service';
import { Broadcaster } from '../events/broadcaster.class';
import { EventService } from '../events/event.service';
import { PlatformService } from '../platform/platform.service';
import { StorageService } from '../storage/storage.service';
import { TenantService } from '../tenant/tenant.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  public  authWaiting: boolean = false;
  public  errorMessage: string = null;
  private isCordova: boolean = this.platformService.isCordova;
  public  loginFailed: string = null;
  private logger: Logger;
  public  recoveringPassword: boolean = false;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private dialogService: DialogService,
    private environmentService: EnvironmentService,
    private eventService: EventService,
    private loggingService: LoggingService,
    public navController: NavController,
    private platformService: PlatformService,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userService: UserService
  ) {
    this.logger = loggingService.getLogger("[LogoutService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public logOut() {
    this.logger.info('logOut()');
    this.storageService.removeItemFromKeyValueTable('lastUsedTenant')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe(()=> {
      this.authService.deAuthenticateUser()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.environmentService.resetEnvironmentEndpoints().subscribe(() => {
          this.userService.resetUser();
          this.tenantService.resetTenant();
          this.unSubscribe.next();
          this.unSubscribe.complete();

          if (!this.isCordova) {
            location.search = '';
          }
          this.eventService.resetPusher();
          if (this.isCordova) {
            // this.dialogService.showSplashScreen('LogoutService - logOut() - splashScreen');

          }
          location.reload();
          // this.navController.navigateRoot('/sign-in');
        });
      });
    });
  }

  public logOutUnAuthenticatedUser(navParams) {
    this.logger.info('logOutUnAuthenticatedUser()');
    this.storageService.removeItemFromKeyValueTable('lastUsedTenant')
    .pipe(takeUntil(this.unSubscribe))
    .subscribe(()=> {
      this.authService.removeStorageData()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.unSubscribe.next();
        this.unSubscribe.complete();

        this.userService.resetUser();
        this.tenantService.resetTenant();
        this.eventService.resetPusher();
        if (this.isCordova) {
          // this.dialogService.showSplashScreen('LogoutService - logOutUnAuthenticatedUser() - splashScreen');
          this.environmentService.resetEnvironmentEndpoints().subscribe(() => {
            // location.reload();
            this.navController.navigateRoot(['/sign-in'], {queryParams: navParams});
          });
        } else {
          location.search = '';
          this.navController.navigateRoot(['/network-check'], { queryParams: navParams });

          // this.navController.navigateForward(['/printer-list'], {queryParams: { resource: this.releaseResource }});

        }
      });
    });
  }
}