import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ModalController, NavParams, Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { PrintJobService } from 'src/app/services/print-job/print-job.service';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-http-error-modal',
  templateUrl: './http-error-modal.component.html',
  styleUrls: ['./http-error-modal.component.scss'],
})
export class HttpErrorModalComponent implements OnInit {

  private errorContentExpanded: boolean = false;
  public  currentLanguage: String = null;
  public hasServerError: boolean = false;
  public hasInternalError: boolean = false;
  public httpRequestId: string = null;
  public httpErrorMessage: string = null;
  public httpErrorCode: string = null;
  public httpErrorText: string = null;
  public httpErrorStatus: number = null;
  public interval: number = 1000;
  private logger: Logger;
  public requestError: boolean = false;
  public requestMethod  : string = null;
  public requestTimedOut: boolean = false;
  public requestUrl: string = null;
  public showHttpErrorModal: boolean = false;
  public unSubscribe: Subject<boolean> = new Subject<boolean>();
  public unSubscribeInterval: Subject<boolean> = new Subject<boolean>();

  @ViewChild("errorMessageContent") errorMessageContent: any;

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorService,
    private loggingService: LoggingService,
    public modalCtrl: ModalController,
    public navParams: NavParams,
    public platform: Platform,
    public printerService: PrinterService,
    public printJobService: PrintJobService,
    private renderer: Renderer2,
    public tenantService: TenantService,
    public userService: UserService
  ) {
    this.logger = loggingService.getLogger("[HttpErrorModalComponent]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  ngOnInit() {
    this.startModal();
  }

  private startModal() {
    this.logger.info('startModal()');
    this.dialogService.hideLoadingSpinnerDialog('ErrorModal - startModal()').then(() => {
      if (this.navParams && !this.showHttpErrorModal) {
        let httpErrorObject: object = this.navParams.get('errorObject');
        if (httpErrorObject['httpErrorResponse'] === 'TimeoutError') {
          this.handleTimeOutError(httpErrorObject);
        } else {
          this.handleHttpError(httpErrorObject);
          // this.handleHttpMOCKError(httpErrorObject);
        }
      }
    });
  }

  private handleTimeOutError(httpErrorObject): void {
    const methodName = 'handleTimeOutError() ';
    this.logger.info(methodName);
    this.errorService.checkServerHealth() // check server health, to determine if there is a general issue with the server
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((response) => {
      // The server is ok, show connection failed popup, to let the user know there was a timeout
      this.logger.info(methodName + 'External api is ok');
      this.showHttpErrorModal = true;
      this.requestTimedOut = true;
      this.unSubscribe.next();
      this.unSubscribe.complete();
    }, (httpErrorResponse: HttpErrorResponse) => {
      // Server unavailable, show server error popup
      this.logger.info(methodName + 'SERVER UNAVAILABLE');
      this.requestUrl = httpErrorResponse['url'] ? httpErrorResponse['url'] : '';
      this.httpRequestId = httpErrorResponse.error && httpErrorResponse.error['requestId'] ? httpErrorResponse.error['requestId'] : '';
      this.httpErrorStatus = httpErrorResponse['status'] ? httpErrorResponse['status'] : 500;
      this.httpErrorMessage = httpErrorResponse['message'] ? httpErrorResponse['message'] : '';
      this.setErrorStatus();
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
  }

  private handleHttpMOCKError(httpErrorObject: object): void {
    console.log('httpErrorObject', httpErrorObject);
    let httpErrorResponse = httpErrorObject;
    this.requestMethod = 'GET';
    this.requestUrl = 'http://rightbackatya';
    this.httpErrorCode = 'PRINTER_NOT_MONITORED';
    this.httpErrorText = 'The printer is not monitored.';
    this.httpRequestId = '32d5949f-0149-48f9-ad12-5025ea9da8e9';
    this.httpErrorMessage = 'yougetit';
    if (httpErrorResponse['status'] === 0) {
      this.httpErrorStatus = 0;
    } else {
      this.httpErrorStatus = 500.000;
    }
    this.setErrorStatus();
  }

  private handleHttpError(httpErrorObject: object): void {
    this.logger.info('handleHttpError()');
    let httpErrorResponse: HttpErrorResponse = httpErrorObject['httpErrorResponse'];
    this.requestMethod = httpErrorObject['requestMethod'] ? httpErrorObject['requestMethod'] : '';
    this.requestUrl = httpErrorResponse['url'] ? httpErrorResponse['url'] : '';
    this.httpErrorCode = httpErrorResponse['error'] && httpErrorResponse['error']['errorCode'] ? httpErrorResponse['error']['errorCode'] : '';
    this.httpErrorText = httpErrorResponse['error'] && httpErrorResponse['error']['errorText'] ? httpErrorResponse['error']['errorText'] : '';
    this.httpRequestId = httpErrorResponse.headers.get('x-printix-request-id');
    this.httpErrorMessage = httpErrorResponse['message'] ? httpErrorResponse['message'] : '';
    if (httpErrorResponse['status'] === 0) {
      this.httpErrorStatus = 0;
    } else {
      this.httpErrorStatus = httpErrorResponse['status'] ? httpErrorResponse['status'] : null;
    }
    this.setErrorStatus();
  }

  private setErrorStatus(): void {
    this.logger.info('setErrorStatus()');
    if (this.httpErrorStatus > 499 || this.httpErrorStatus === 0) {
      this.errorService.canConnectToServer() // retry server connection once
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((canConnectToServer: boolean) => {
        this.logger.info('canConnectToServer: ' + canConnectToServer);
        this.showHttpErrorModal = true;
        if (!canConnectToServer) {
          this.hasServerError = true;
          this.tryServerAtInterval(); // then retry server connect with an increasing interval
        } else {
          this.requestError = true;
        }
        this.unSubscribe.next();
        this.unSubscribe.complete();
      });
    } else if (this.httpErrorStatus < 500) {
      this.showHttpErrorModal = true;
      this.hasInternalError = true;
    }
  }

  private tryServerAtInterval(): void {
    this.logger.info('tryServerAtInterval()');
    setTimeout(() => {
      this.errorService.canConnectToServer()
      .pipe(takeUntil(this.unSubscribeInterval))
      .subscribe((canConnectToServer: boolean) => {
        if (canConnectToServer) {
          this.unSubscribeInterval.next();
          this.unSubscribeInterval.complete();
          this.dismiss();
        } else {
          this.unSubscribeInterval.next();
          this.unSubscribeInterval.complete();
          this.increaseInterval();
        }
      });
    }, this.interval);
  }

  private increaseInterval() {
    this.logger.info('increaseInterval()');
    if (this.interval < 5000) {
      this.interval += 500;
    } else if (this.interval < 10000) {
      this.interval += 1000;
    } else if (this.interval < 30000) {
      this.interval += 2000;
    } else if (this.interval < 60000) {
      this.interval += 4000;
    }
    this.tryServerAtInterval();
  }

  public toggleErrorMessageContent(): void {
    if (!this.requestTimedOut) {
    this.logger.info('(click) toggleErrorMessageContent()');
      let height: string = this.errorMessageContent.nativeElement.scrollHeight + "px";
      this.renderer.setStyle(this.errorMessageContent.nativeElement, "transition", "max-height 300ms, padding 300ms");

      if (this.errorContentExpanded) {
        this.renderer.setStyle(this.errorMessageContent.nativeElement, "max-height", "0");
      } else {
        this.renderer.setStyle(this.errorMessageContent.nativeElement, "max-height", height);
      }
      this.errorContentExpanded = !this.errorContentExpanded;
    }
  }

  public dismiss(returnData?): void {
    this.logger.info('dimiss()');
    this.modalCtrl.dismiss(returnData);
  }
}