import { environment_dev } from '../environments/environment.dev';

export function getPxdDevEnvironmentFromSubdomain(subdomain: string): any {
  if (subdomain.indexOf('dev') != 0 || subdomain.length != 17) {
    return {};
  }

  // devNN.printix.dev environments should have the same configuration as devenv, except for the URL endpoints.
  const environment_pxd_dev = JSON.parse(JSON.stringify(environment_dev));
  const replacementKeys = ['appEndpoint', 'checkServerHealth', 'internalTestWaitTime', 'internalTestReturnSetStatusCode',
    'endpointApi', 'endpointCodeExchange', 'endpointLogOut', 'endpointLogInWepAppTenant', 'endpointLogInMS',
    'endpointLogInGoogle', 'endpointGetTenantUser', 'endpointFindTenant'];
  replacementKeys.forEach(key => {
    environment_pxd_dev[key] = environment_pxd_dev[key].replace('devenv.printix.net', subdomain);
  });

  // Set the environment prefix like 'environment_pxd_dev01'
  environment_pxd_dev.environmentPrefix = 'environment_pxd_' + subdomain.replace('.printix.dev','');

  return environment_pxd_dev;
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.