import { PAGING_DIRECTION } from './paging-direction.model';

export abstract class PagingObject {

  public page: number = 0;
  public pageSize: number = 8;
  public direction: PAGING_DIRECTION = PAGING_DIRECTION.ASC;
  public match: string = null;

  constructor() {
  }

  protected getPagingUrlString(): string {
    return '';
  }
}