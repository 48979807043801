import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { EnvironmentService } from '../environment/environment.service';
import { ErrorService } from '../error/error.service';

@Injectable({
  providedIn: 'root'
})
export class VersionControlService {

    // private versionControl: string = '/assets/json/language/supported-languages/supported-languages.json';
    private versionControl: string = '/assets/json/release-app-version.json';  // change for new version check
    private httpErrorResponse: HttpErrorResponse;
    private logger: Logger;

  constructor(
    private environmentService: EnvironmentService,
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private loggingService: LoggingService,
  ) {
    this.logger = loggingService.getLogger("[VersionControlService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public getCurrentAppVersion(appEndpoint): Observable<any> {
    this.logger.info('getCurrentAppVersion()');
    return new Observable((observer) => {
      let getVersionUrl = appEndpoint + this.versionControl;
      this.httpClient.get<any>(getVersionUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getCurrentAppVersion() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[VersionControlService] getCurrentAppVersion()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((currentVersion) => {
        observer.next(currentVersion);
        observer.complete();
      });
    });
  }
}