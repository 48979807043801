import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavController, NavParams, Platform, PopoverController } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { PopoverComponent } from 'src/app/components/popover/popover.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { ErrorService } from 'src/app/services/error/error.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { EventService } from 'src/app/services/events/event.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { PagingService } from 'src/app/services/paging/paging.service';
import { PrintJobService } from 'src/app/services/print-job/print-job.service';
import { IPrinter } from 'src/app/services/printer/models/printer.model';
import { IQueue } from 'src/app/services/printer/models/queue.model';
import { PrinterService } from 'src/app/services/printer/printer.service';
import { ReleaseResourceService } from 'src/app/services/release-resource/release-resource.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { ITenant } from 'src/app/services/tenant/models/tenant.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { IUser } from 'src/app/services/user/models/user.model';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-printer-list',
  templateUrl: './printer-list.page.html',
  styleUrls: ['./printer-list.page.scss'],
})
export class PrinterListPage implements OnInit {

  public appIsActive = true;
  public isHybrid = true;
  public printerQueuesList: Array<IQueue> = [];
  private printerList: Array<IPrinter> = [];
  public storedNetworkFilters: Array<object> = [];
  private storedNetworkIds: Array<string> = [];
  private dismissedNetwork: Array<string> = [];
  private tenant: ITenant = null;
  private networks: Array<string> = [];
  private tenantNetworks: any;
  public loadingPage: boolean = true;
  private logger: Logger;
  private user: IUser = null;
  private filterObjectsKey: string = 'filter_objects';
  public pagingObject: any = {};
  public currentPage: number = 0;
  private pageSize: number = 9;
  private urlString: string;
  private searchString: string = null;
  private releaseResource: any = null;
  private resourceId: string = null;
  private resourceFilters: Array<string> = null;
  private matchingQueues: string = null;
  public nav: any;
  public platformResumeEventSubscription: Subscription = null;
  public updatePrinterListEventSubscription: Subscription = null;
  public tenantUpdatedEventSubscription: Subscription = null;
  private previousSearchString: string = null;
  private previousNetworksFilter: Array<any> = [];
  private componentSubscription: Subject<void> = new Subject<void>();
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  private currentLanguage: String = null;
  private currentNetworksPage: number = 0;
  private networksPageSize: number = 5;
  private networkSort: string = 'NAME';
  private networkDirection: string = 'ASC';
  private networksEndpoint: string = null;
  public myInput: string;
  public getTenantAndUserSubscription: Subject<void> = new Subject<void>();
  public  isInternetAccessAvailable = true;
  public isInternetAvailableSubscription: Subscription = null;
  private showPrinterInfo = false;

  constructor(
    private authService: AuthService,
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private eventService: EventService,
    private errorService: ErrorService,
    private loggingService: LoggingService,
    private modalCtrl: ModalController,
    private navParams: NavParams,
    private navController: NavController,
    private networkService: NetworkService,
    private pagingService: PagingService,
    public platform: Platform,
    private popoverCtrl: PopoverController,
    private printerService: PrinterService,
    private printJobService: PrintJobService,
    private releaseResourceService: ReleaseResourceService,
    private router: Router,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userService: UserService
  ) {
    this.logger = loggingService.getLogger("[PrinterListPage]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.platform.pause.subscribe(async () => {
      this.appIsActive = false;
    });

    this.platformResumeEventSubscription = this.platform.resume.subscribe(async () => {
      this.appIsActive = true;
      if (this.isCurrentUrlThisPage()) {
        this.refreshPrinterQueuesList();
      }
    });

    this.updatePrinterListEventSubscription = broadcaster.on('updatePrinterList').subscribe(() => {
      if (this.appIsActive && this.isCurrentUrlThisPage()) {
        this.refreshPrinterQueuesList();
      }
    });

    this.tenantUpdatedEventSubscription = broadcaster.on('tenant updated').subscribe(() => {
      if (this.appIsActive && this.isCurrentUrlThisPage()) {
        this.refreshPrinterQueuesList();
      }
    });

    this.isHybrid = this.platform.is('hybrid');
  }

  ngOnInit() {
  }

  private isCurrentUrlThisPage() {
    let isThisPage = false;
    let currentUrl = null;
    let urlTree = this.router.parseUrl(this.router.url);
    urlTree.queryParams = {}
    currentUrl = urlTree.toString();
    if (currentUrl && currentUrl === '/printer-list') {
      isThisPage = true;
    };
    return isThisPage;
  }

  private initPage(): void {
    this.logger.info('initPage()');
    this.user = this.userService.getCurrentUser();
    this.tenant = this.tenantService.tenant;
    this.startListener();
    this.updatePageTitle();
    this.refreshPrinterQueuesList();
  }

  public refreshPrinterQueuesList() {
    this.logger.info('refreshPrinterQueuesList()');
    this.eventService.startTokenExpireCountDown();
    this.currentLanguage = this.userService.user.embedded.user.language || null;
    this.networksEndpoint = this.tenant.links.networks;
    this.tenantService.getTenantNetworks(this.networksEndpoint, [], this.currentNetworksPage, this.networksPageSize, this.networkSort, this.networkDirection, null, null)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((networksList) => { // 1. get tenant networks
      this.tenantNetworks = networksList;
      this.unSubscribe.next();
      this.unSubscribe.complete();
      this.storageService.getItemFromKeyValueTable(this.filterObjectsKey)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((storedNetworkFilters: any) => { // 2. get stored network filters
        storedNetworkFilters = storedNetworkFilters ? storedNetworkFilters : [];
        if (storedNetworkFilters.length > 0) {
          let storedNetworkIds: Array<string> = [];
          storedNetworkFilters.forEach(nw => {
            storedNetworkIds.push(nw.id);
          });
          let pageSize = storedNetworkFilters.length;
          this.tenantService.getTenantNetworks(this.networksEndpoint, storedNetworkIds, this.currentNetworksPage, pageSize, this.networkSort, this.networkDirection, null, null)
          .pipe(takeUntil(this.unSubscribe))
          .subscribe((storedNetworksList) => {
            let pagingObjectIndex: number = storedNetworksList.length -1;
            storedNetworksList.splice(pagingObjectIndex, 1);
            this.storedNetworkFilters = [];
            this.storedNetworkIds = [];
            for (let i = 0; i < storedNetworksList.length; i++) {
              let network: Object = {
                name: storedNetworksList[i].name,
                id: storedNetworksList[i].links.self,
                checked: true
              };
              this.storedNetworkFilters.push(network);
              this.storedNetworkIds.push(storedNetworksList[i].links.self);
            }
            this.getPrintersList();
            this.unSubscribe.next();
            this.unSubscribe.complete();
          }, (error: any) => {
            this.unSubscribe.complete();
          });
        } else {
          this.storedNetworkFilters = [];
          this.storedNetworkIds = [];
          this.getPrintersList();
          this.unSubscribe.next();
          this.unSubscribe.complete();
        }
      }, (error: any) => {
        this.unSubscribe.complete();
      });
    });
  }

  private getPrintersList(): void {
    this.logger.info('getPrintersList()');
    this.releaseResource = this.navParams.get('resource') ? this.navParams.get('resource') : null;
    this.resourceId = this.releaseResource ? this.releaseResource.resourceId : null;
    this.resourceFilters = this.releaseResource ? this.releaseResource.filter : null;
    this.matchingQueues = this.releaseResource ? this.releaseResource.matchingQueues : null;

    if (this.platform.is('ipad') || this.platform.is('tablet')) { this.pageSize = 20; }

    if (this.searchString !== null && this.previousSearchString !== this.searchString) { this.currentPage = 0; }
    if (this.previousNetworksFilter.length !== this.storedNetworkFilters.length) { this.currentPage = 0; }

    this.previousSearchString = this.searchString;
    this.previousNetworksFilter = [];

    this.storedNetworkFilters.forEach(nwf => {
      this.previousNetworksFilter.push(nwf);
    });

    this.dialogService.showLoadingSpinnerDialog('getPrintersList()->getAvailablePrintersList()').subscribe(() => {
      if (this.releaseResource && this.releaseResource.releaseJobs.length > 0) {
        this.releaseResourceService.getAvailablePrintersList(this.matchingQueues, null, this.currentPage, this.pageSize, this.searchString, this.storedNetworkIds)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((availablePrinters) => {
          this.dialogService.hideLoadingSpinnerDialog('getPrintersList()->getAvailablePrintersList() has releaseJobs');
          this.printerQueuesList = availablePrinters.queues;
          this.printerList = availablePrinters.printers;
          this.pagingObject = availablePrinters.page;

          this.setFavoriteQueues();
          this.setPrinterDetailsOnQueue();
          this.loadingPage = false;
          this.unSubscribe.next();
          this.unSubscribe.complete();
        });
      } else {
        let queueParameters = this.pagingService.getQueueParameters(this.currentPage, this.pageSize, [], this.searchString, []);
        queueParameters.printerTypes = ['NETWORK', 'VIRTUAL'];
        if (this.storedNetworkFilters.length > 0) {
          this.storedNetworkFilters.forEach(nw => {
            queueParameters.networks.push(nw['id']);
          });
        }
        this.printerService.getPrinterQueuesList(queueParameters)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((queues: Array<IQueue>) => {
          this.printerList = [];
          this.printerQueuesList = [];
          this.pagingObject = queues.pop()['page'];
          this.printerList = queues.pop()['printers'];
          this.printerQueuesList = queues;

          this.dialogService.hideLoadingSpinnerDialog('getPrintersList()->getAvailablePrintersList() - does NOT have release jobs');
          this.setFavoriteQueues();
          this.setPrinterDetailsOnQueue();
          this.loadingPage = false;
          this.unSubscribe.next();
          this.unSubscribe.complete();
        });
      }
    });
  }

  private setPrinterDetailsOnQueue() {
    this.logger.info('setPrinterDetailsOnQueue()');
    this.printerQueuesList.forEach(q => {
      this.printerList.forEach(p => {
        if(q.signId === p.signId) {
          q.printerDetails.location = p.location;
          q.printerDetails.colorSupport = p.colorSupport;
          q.printerDetails.duplexSupport = p.duplexSupport;
        }
      });
    });
  }

  public getNextPage(showPrevious?) {
    this.logger.info('getNextPage()');
    if (showPrevious) {
      this.currentPage--;
    } else {
      this.currentPage++;
    }
    if (this.appIsActive) {
      this.refreshPrinterQueuesList();
    }
  }

  public setFavoriteQueues(): void {
    this.logger.info('setFavoriteQueues()');
    let pinnedQueues = this.user.userAppSettings.pinnedPrinterQueues ? this.user.userAppSettings.pinnedPrinterQueues : [];
    for (let i = 0; i < this.printerQueuesList.length; i++) {
      for (let j = 0; j < pinnedQueues.length; j++) {
        if (pinnedQueues[j] === this.printerQueuesList[i].links.self) {
          this.printerQueuesList[i].isFavorite = true;
        }
      }
    }
  }

  public async itemClick(target, event): Promise<void> {
    if (event === 'selectPrintQueue') {
      this.selectPrintQueue(target);
    } else {
      this.presentModal(event, target);
    }
  }

  private selectPrintQueue(target) {
    this.logger.info('selectPrintQueue()');
    let targetPrinter: IPrinter;
    const targetQueue: IQueue = target;

    this.printerList.forEach(printer => {
      if (printer.links.self === target.links.printer) {
        targetPrinter = printer;
      }
    });
    if (target.isFavorite !== true) {
      target.isFavorite = true;
      this.userService.changePrinterQueuePinStatus(target.links.self, target.isFavorite)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.user.userAppSettings.lastUsedPrinter = target.links.printer;
        this.user.userAppSettings.lastUsedQueue = target.links.self;
        this.unSubscribe.next();
        this.unSubscribe.complete();

        this.userService.setLastUsedPrinter(targetPrinter) // set last used printer
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(() => {
          this.logger.info('releaseSelectedPrintJobs() - setting lastUsedQueue: ' + target.links.self);
          this.userService.setLastUsedQueue(targetQueue)
          .pipe(takeUntil(this.unSubscribe))
          .subscribe(() => {
            this.unSubscribe.next();
            this.unSubscribe.complete();
            this.navController.back();

          });
        });
      });
    } else {
      this.userService.setLastUsedPrinter(targetPrinter) // set last used printer
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.userService.setLastUsedQueue(targetQueue)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(() => {
          this.unSubscribe.next();
          this.unSubscribe.complete();
          this.navController.back();
        });
      });
    }
  }

  public async presentModal(event: string, target): Promise<void> {
    this.logger.info('(click) presentModal()');
    if (!this.showPrinterInfo) {
      this.showPrinterInfo = true;
      let currentPrinterUrl = target.links.printer;
      let printerParameters = this.pagingService.getPrinterParameters();
      this.urlString = this.pagingService.getUrlString(0, 5);
      printerParameters.printers = [];
      printerParameters.printers.push(currentPrinterUrl);

      this.printerService.getPrinterList(printerParameters, this.urlString)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe( async (printer: Array<IPrinter>) => {
        this.unSubscribe.next();
        this.unSubscribe.complete();


        const dataPackage = {
          printer: printer[0],
          cssClass: event
        }

        const infoModal = await this.modalCtrl.create({
          component: ModalComponent,
          componentProps: {dataPackage},
          cssClass: event
        });

        await infoModal.present();

        await infoModal.onDidDismiss()
        .then(() => {
          this.showPrinterInfo = false;
        });
      });
    }
  }

  public toggleFavoritePrinterQueue(printQueue: IQueue): void {
    this.logger.info('(click) toggleFavoritePrinterQueue()');
    printQueue.isFavorite = !printQueue.isFavorite;
    this.changeClickedPrinterQueuePinStatus(printQueue.links.self, printQueue.isFavorite);
  }

  private changeClickedPrinterQueuePinStatus(queue, isFavorite) {
    this.dialogService.showLoadingSpinnerDialog('changeClickedPrinterQueuePinStatus()').subscribe(() => {
      this.userService.changePrinterQueuePinStatus(queue, isFavorite)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.dialogService.hideLoadingSpinnerDialog('changeClickedPrinterQueuePinStatus()');
        this.unSubscribe.next();
        this.unSubscribe.complete();
      });
    });
  }

  public dismissSelectedFilter(filter: any) {
    let dismissedNetworkIndex = this.storedNetworkFilters.indexOf(filter);
    this.storedNetworkFilters.splice(dismissedNetworkIndex, 1);
    this.storageService.addItemToKeyValueTable(this.filterObjectsKey, this.storedNetworkFilters)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe(() => {
      this.unSubscribe.next();
      this.unSubscribe.complete();
      if (this.appIsActive) {
        this.refreshPrinterQueuesList();
      }
    });
  }

  public onSearch(event, searchInput) {
    this.searchString = searchInput;
    if (this.appIsActive) {
      this.refreshPrinterQueuesList();
    }
  }

  public async presentPopover(myEvent, popoverType: string): Promise<void> {

    let dataPackage: any = {
      type: popoverType,
      networks: this.tenantNetworks,
      resourceId: this.resourceId,
      storedNetworks: this.storedNetworkFilters
    };

    const popover = await this.popoverCtrl.create({
      component: PopoverComponent,
      event: myEvent,
      translucent: true,
      componentProps: { dataPackage },
      cssClass: popoverType,
      animated: true
    });

    await popover.present();

    await popover.onDidDismiss()
    .then((chosenFilters) => {
      if (chosenFilters.data === 'clearAllFilters') {
        this.storageService.removeItemFromKeyValueTable(this.filterObjectsKey)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe(()=> {
          this.unSubscribe.next();
          this.unSubscribe.complete();
          if (this.appIsActive) {
            this.refreshPrinterQueuesList();
          }
        });
      } else if (chosenFilters.data === 'showNetworksModal') {
        this.presentNetworksListModal();
      }
    });

  }

  private async presentNetworksListModal(): Promise<void> {
    let dataPackage: any = {
      networks: this.tenantNetworks,
      resourceId: this.resourceId,
      storedNetworks: this.storedNetworkFilters,
      cssClass: 'networksList'
    };

    const infoModal = await this.modalCtrl.create({
      component: ModalComponent,
      componentProps: {dataPackage},
      cssClass: 'networksList'
    });

    await infoModal.present();

    await infoModal.onDidDismiss()
    .then(() => {
      if (this.appIsActive) {
        this.refreshPrinterQueuesList();
      }
    });
  }

  public goToPreviousPage() {
    this.navController.pop();
  }

  private updatePageTitle() {
    this.broadcaster.broadcast('update title', 'Printers');
  }

  private startListener(): void {
    this.broadcaster.on<string>('languageChange')
    .pipe(takeUntil(this.componentSubscription))
    .subscribe(data => {
      this.currentLanguage = data === 'no' ? 'nb' : data;
    });

    this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
      this.changeDetectorRef.detectChanges();
      console.log('isInternetAccessAvailable', isInternetAccessAvailable);
    });
  }

  ionViewWillLeave(): void {
		this.isInternetAvailableSubscription.unsubscribe();
    this.platformResumeEventSubscription.unsubscribe();
    this.updatePrinterListEventSubscription.unsubscribe();
    this.tenantUpdatedEventSubscription.unsubscribe();
    this.componentSubscription.next();
    this.componentSubscription.complete();
  }

  ionViewDidEnter(): void {
    this.storageService.getItemFromKeyValueTable('lastUsedTenant')
    .pipe(takeUntil(this.getTenantAndUserSubscription))
    .subscribe((tenant: ITenantLite) => {
      this.logger.info('got last used tenant');
      if (tenant) {
        this.tenantService.refreshCurrentTenant(tenant.links.self)
        .pipe(takeUntil(this.getTenantAndUserSubscription))
        .subscribe((tenant) => {
          this.userService.refreshCurrentUser(this.tenantService.tenant.links.currentUser)
          .pipe(takeUntil(this.getTenantAndUserSubscription))
          .subscribe((user) => {
            this.user = user;
            this.tenant = tenant;
            this.getTenantAndUserSubscription.next();
            this.getTenantAndUserSubscription.complete();
            this.initPage();
          });
        });
      } else {
        this.authService.logOutUnAuthenticatedUser('ACCESS_DENIED');
      }
    });
  }
}