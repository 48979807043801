import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorService } from '../error/error.service';
import { TenantService } from '../tenant/tenant.service';
import { UserService } from '../user/user.service';
import { isArray } from '../utils/utils';
import { IPrintJob } from './models/print-job.model';
import * as _ from 'lodash';
import { Observable, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { Logger, LoggingService } from 'ionic-logging-service';

export const PRINT_JOB_STATUS = [
{
  status: "RELEASING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "RELEASE_DELIVERY_FAILED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "RELEASE_FAILED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "RELEASE_TIMED_OUT",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "RELEASED",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "AWAIT_PRINT",
  showStatusInfo: false,
  showLoader: false,
  isSuccess: false,
  isError: false
},
{
  status: "PRINTING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "PRINTING_FAILING",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "PRINTED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: true,
  isError: false
},
{
  status: "DELETING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "DELETE_FAILED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "DELETE_TIMED_OUT",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "DELETED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: true,
  isError: false
},
{
  status: "QUEUEING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "TRANSFERING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "UPLOADING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "UPLOAD_FAILED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "UPLOAD_TIMED_OUT",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "UPLOADED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: true,
  isError: false
},
{
  status: "CONVERTING",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "CONVERTED",
  showStatusInfo: false,
  showLoader: false,
  isSuccess: false,
  isError: false
},
{
  status: "PRINT_OK",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: true,
  isError: false
},
{
  status: "PRINT_CANCELLED",
  showStatusInfo: false,
  showLoader: false,
  isSuccess: false,
  isError: false
},
{
  status: "PRINT_FAILED",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "PRINT_OK_SENT_TO_QUEUE",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: false
},
{
  status: "PRINT_OK_SENT_TO_SERVER",
  showStatusInfo: true,
  showLoader: true,
  isSuccess: false,
  isError: false
},
{
  status: "PRINT_FAILED_SENT_TO_QUEUE",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "PRINT_FAILED_SENT_TO_SERVER",
  showStatusInfo: true,
  showLoader: false,
  isSuccess: false,
  isError: true
},
{
  status: "IN_CLOUD_STORAGE",
  showStatusInfo: false,
  showLoader: false,
  isSuccess: false,
  isError: false
}
];

@Injectable({
  providedIn: 'root'
})

export class PrintJobService {

  public currentNetwork: Array<string> = [];
  public PRINT_JOB_APP_STATES: Array<string> = [];
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;

  constructor(
    private errorService: ErrorService,
    private httpClient: HttpClient,
    private tenantService: TenantService,
    private loggingService: LoggingService,
    private userService: UserService,
  ) {
    this.logger = loggingService.getLogger("[PrintJobService]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    this.PRINT_JOB_APP_STATES = [
      "PRINT_OK",
      "PRINT_FAILED",
      "CONVERTED"
    ];
  }

  private changesListenerStart(): void {
    // this.patchObserver = jsonPatch.observe(this.user.userData, null);
  }

  private changesListenerStop(): void {
    // if (this.patchObserver && this.user) {
    // jsonPatch.unobserve<IUserData>(this.user.userData, this.patchObserver);
    // }
  }

  private deserializeLinkObjects(printJob: IPrintJob, links: any): void {
    if (links) {
      if (links['self'] && links['self'].href) {
        printJob.links.self = links['self'].href;
      }
      if (links['px:printer'] && links['px:printer'].href) {
        printJob.links.printer = links['px:printer'].href;
      }
      if (links['px:workstation'] && links['px:workstation'].href) {
        printJob.links.workstation = links['px:workstation'].href;
      }
      if (links['px:queue'] && links['px:queue'].href) {
        printJob.links.queue = links['px:queue'].href;
      }
      if (links['px:finishedRelease'] && links['px:finishedRelease'].href) {
        printJob.links.finishedRelease = links['px:finishedRelease'].href;
      }
      if (links['px:ongoingRelease']) {
        if (links['px:ongoingRelease'].href) {
          printJob.links.ongoingRelease.push(links['px:ongoingRelease']);
        } else if (isArray(links['px:ongoingRelease'])) {
          printJob.links.ongoingRelease = links['px:ongoingRelease'];
        }
      }
    }
  }

  private deserializeEmbeddedObjects(printJob: IPrintJob, embedded: any): void {
    if (embedded) {
    }
  }

  private setJobState = function (printJob: IPrintJob) {
    printJob.options.printJobStatus = _.find(PRINT_JOB_STATUS, (statusObj) => {
      return statusObj.status === printJob.jobState;
    });
  };

  public deserializePrintJob(input: any, skipObservers: boolean): IPrintJob {
    let printJob: IPrintJob = {
      links: {
        self: null,
        printer: null,
        workstation: null,
        queue: null,
        ongoingRelease: [],
        finishedRelease: null
      },
      color: input.color ? input.color : false,
      cloudStoreReferences: input.cloudStoreReferences ? input.cloudStoreReferences : [],
      deletedTime: input.deletedTime ? input.deletedTime : null,
      duplex: input.duplex ? input.duplex : false,
      jobName: input.jobName ? input.jobName : null,
      jobState: input.jobState ? input.jobState : null,
      jobNetwork: this.currentNetwork ? this.currentNetwork : [],
      pageCount: input.pageCount ? input.pageCount : 0,
      paperSize: input.paperSize ? input.paperSize : null,
      pdl: input.pdl ? input.pdl : null,
      pinned: input.pinned ? input.pinned : false,
      restoreTime: input.restoreTime ? input.restoreTime : null,
      submitTime: input.submitTime ? input.submitTime : null,
      options: {
        isSelectedForRestore: false,
        isSelected: false,
        resourceErrors: [],
        printJobStatus: {
          status: null,
          showLoader: false,
          isError: false,
          isSuccess: false,
          showStatusInfo: false
        }
      }
    };
    if (input._links) {this.deserializeLinkObjects(printJob, input._links);}
    if (input._embedded) {this.deserializeEmbeddedObjects(printJob, input._embedded);}
    this.setJobState(printJob);
    if (!skipObservers) {
      this.changesListenerStart();
    }
    return printJob;
  }

  private deserializePrintJobList(input: any): Array<IPrintJob> {
    let printJobList: Array<IPrintJob> = [];
    if (input._embedded && input._embedded.printJobs) {
      for (let printJob of input._embedded.printJobs) {
        printJobList.push(this.deserializePrintJob(printJob, true));
      }
    }
    return printJobList;
  }

  public getPrintJobList(): Observable<Array<IPrintJob>> {
    this.logger.info('getPrintJobList()');
    return new Observable((observer) => {
      this.httpClient.get<any>(this.userService.user.links.printJobs)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getPrintJobList() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[PrintJobService] getPrintJobList()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(this.deserializePrintJobList(response));
        observer.complete();
      });
    });
  }

  public getSinglePrintJob(printJobUrl: string): Observable<IPrintJob> {
    this.logger.info('getSinglePrintJob()');
    return new Observable((observer) => {
      this.httpClient.get<any>(printJobUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('getSinglePrintJob() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[PrintJobService] getSinglePrintJob()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response: any) => {
        observer.next(this.deserializePrintJob(response, true));
        observer.complete();
      });
    });
  }

  public getPrintJobs(): Observable<Array<IPrintJob>> {
    this.logger.info('getPrintJobList()');
    return new Observable<Array<IPrintJob>>((observer: Subscriber<Array<IPrintJob>>) => {
      this.getPrintJobList().subscribe((printJobList: Array<IPrintJob>) => {
        observer.next(printJobList);
        observer.complete();
      });
    });
  }

  public getWorkstation(workstationUrl): Observable<any> {
    this.logger.info('getWorkstation()');
    return new Observable<any>((observer) => {
      this.httpClient.get(workstationUrl)
      .subscribe((workstation: any) => {
        // this.dialogService.hideLoadingSpinnerDialog('ModalComponent - getworkstationName()');
        observer.next(workstation);
        observer.complete();
      },(httpErrorResponse: HttpErrorResponse) => {
        this.logger.info('getWorkstation() - httpErrorResponse: ' + httpErrorResponse);
        const errorString = 'Error: ' + httpErrorResponse['error']['errorText'] + ', status: ' + httpErrorResponse['status'];
        observer.error(errorString);
        observer.complete();
      });
    });
  }

  public deleteSelectedPrintJobs = function(printJobList): Observable<any> {
    this.logger.info('deleteSelectedPrintJobs()');
    let objectToSend: Array<any> = [];
    let url: string = this.tenantService.tenant.links.deletePrintJob;

    _.forEach(printJobList, (printJob) => {
      objectToSend.push({
        printJobId: printJob.links.self,
        permanent: (printJob.deletedTime) ? true : false
      });
    });

    return new Observable((observer) => {
      this.httpClient.post(url, objectToSend)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(3),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403) {
            this.logger.info('deleteSelectedPrintJobs() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[PrintJobService] deleteSelectedPrintJobs()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        observer.next();
        observer.complete();
      });
    });
  };
}