import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { LogsService } from '../logs/logs.service';
import { ITenant } from '../tenant/models/tenant.model';
import { TenantService } from '../tenant/tenant.service';
import { IUser } from '../user/models/user.model';
import { UserService } from '../user/user.service';

export const SECURE_PRINT_LEVELS = {
  QR: 'QR',
  NFC: 'NFC',
  ON_DEVICE_RELEASE: 'ON_DEVICE_RELEASE'
};

@Injectable({
  providedIn: 'root'
})

export class SecurePrintService {

  private logger: Logger;

  constructor(
    public http: HttpClient,
    private logsService: LogsService,
    private tenantService: TenantService,
    private userService: UserService,
    private loggingService: LoggingService,
  ) {
    this.logger = loggingService.getLogger("[SecurePrintService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public getUserSecurityLevel(): Observable<string> {
    this.logger.info('getUserSecurityLevel()');
    let securePrintLevel: string = null;
    let user: IUser = this.userService.user;
    let tenant: ITenant = this.tenantService.tenant;

    return new Observable((observer) => {
      let securePrintConfiguration: any = tenant ? tenant.embedded.securePrintConfiguration : null;
      if (securePrintConfiguration) {
        this.logsService.logSecuritySettings(securePrintConfiguration);

        let securityLevelGroupsQR = securePrintConfiguration._embedded.securityLevelQR;
        let securityLevelGroupsNFC = securePrintConfiguration._embedded.securityLevelNFC;
        let securityLevelGroupsON_DEVICE_RELEASE = securePrintConfiguration._embedded.securityLevelON_DEVICE_RELEASE;
        let mustPrintSecurelyOndeviceOnly = securePrintConfiguration.mustPrintSecurelyOndeviceOnly; // Users who must print securely, will only need to do this for printers with Printix Go.(text from printix administrator)


        if (securityLevelGroupsQR.length > 0) {
          securePrintLevel = this.setSecurePrintLevelFromGroup(user, securePrintLevel, securityLevelGroupsQR, 'QR');
        }
        if (securityLevelGroupsNFC.length > 0) {
          securePrintLevel = this.setSecurePrintLevelFromGroup(user, securePrintLevel, securityLevelGroupsNFC, 'NFC');
        }
        if (securityLevelGroupsON_DEVICE_RELEASE.length > 0) {
          securePrintLevel = this.setSecurePrintLevelFromGroup(user, securePrintLevel, securityLevelGroupsON_DEVICE_RELEASE, 'ON_DEVICE_RELEASE');
        }
        if (!securePrintLevel && securePrintConfiguration.minimumSecurityLevel !== 'NONE') {
          securePrintLevel = securePrintConfiguration.minimumSecurityLevel;
        }
        if (securePrintLevel !== null && mustPrintSecurelyOndeviceOnly) {
          securePrintLevel = 'ON_DEVICE_RELEASE';
        }
      }
      observer.next(securePrintLevel);
      observer.complete();
    });
  }

  private setSecurePrintLevelFromGroup(user, securityPrintLevel, securityLevelGroups, securityLevel) {
    if (securityLevelGroups.length > 0 && user.groups.length > 0) {
      securityLevelGroups.forEach(securityLevelGroup => {
        user.groups.forEach(userGroupID => {
          if (userGroupID === securityLevelGroup._links.self.href) {
            securityPrintLevel = securityLevel;
          }
        });
      });
    }
    return securityPrintLevel;
  }

  public setSecurePrintMethods(user): Observable<Array<string>> {
    this.logger.info('setSecurePrintMethods()');
    let securePrintMethods: Array<string> = user.userAppSettings.securePrintMethods;
    return new Observable((observer) => {
      if (!securePrintMethods) {
        securePrintMethods = [SECURE_PRINT_LEVELS.QR, SECURE_PRINT_LEVELS.NFC];
        this.userService.setSecurePrintMethods(securePrintMethods).subscribe((response) => {
          observer.next(securePrintMethods);
          observer.complete();
        });
      } else {
        let onDeviceReleaseIndex: number = securePrintMethods.indexOf('ON_DEVICE_RELEASE');
        if (onDeviceReleaseIndex !== -1) {
          securePrintMethods.splice(onDeviceReleaseIndex, 1);
        }
        observer.next(securePrintMethods);
        observer.complete();

      }
    });
  }
}