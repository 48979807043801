import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';
import { delay, finalize, retryWhen, take, tap } from 'rxjs/operators';
import { DialogService } from '../dialog/dialog.service';
import { ErrorService } from '../error/error.service';
import { IPrintJob } from '../print-job/models/print-job.model';
import { IPrinter } from '../printer/models/printer.model';
import { PrinterService } from '../printer/printer.service';
import { ITenant } from '../tenant/models/tenant.model';
import { TenantService } from '../tenant/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class NfcService {

  public  invalidQRCode: boolean = false;
  private httpErrorResponse: HttpErrorResponse;
  private logger: Logger;
  public  releaseResource: any = null;
  public  selectedPrintJobs: Array<IPrintJob> = [];
  private tenant: ITenant = null;

constructor(
  private dialogService: DialogService,
  private errorService: ErrorService,
  private httpClient: HttpClient,
  private loggingService: LoggingService,
  private printerService: PrinterService,
  private tenantService: TenantService,
) {
  this.logger = loggingService.getLogger("[NfcService]");
  const methodName = "ctor";
  this.logger.entry(methodName);
}

  public getPrinterFromTagId(tagId: string): Observable<IPrinter> {
    this.logger.info('getPrinterFromTagId()');
    this.tenant = this.tenantService.tenant;
    let findPrinterUrl = this.tenant.links.findPrinterByNfcTag + tagId;
    return new Observable((observer) => {
      this.httpClient.get<IPrinter>(findPrinterUrl)
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403 || this.httpErrorResponse.status === 404) {
            this.logger.info('getPrinterFromTagId() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
            this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'GET', '[NfcService] getPrinterFromTagId()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        observer.next(this.printerService.deserializePrinter(response, true));
        observer.complete();
      });
    });
  }

  public registerTagWithPrinter(url: string, nfcId: string): Observable<string> {
    this.logger.info('registerTagWithPrinter()');
      let registerUrl: string = url + nfcId;
      this.logger.info('registerUrl: ' + registerUrl);
      return new Observable((observer) => {
      this.httpClient.post<string>(registerUrl, {})
      .pipe(retryWhen(error => error.pipe(
        delay(1000),
        take(1),
        // return httpErrorResponse.status > 499 ? Observable.of(true) : Observable.throw(httpErrorResponse);
        tap((httpErrorResponse: HttpErrorResponse) => {this.httpErrorResponse = httpErrorResponse}),
        finalize(() => {
          if (this.httpErrorResponse.status === 401 || this.httpErrorResponse.status ===  403 || this.httpErrorResponse.status === 409) {
              this.logger.info('registerTagWithPrinter() httpErrorResponse === ' + this.httpErrorResponse.status);
          } else {
              this.errorService.handleHttpClientResponseError(this.httpErrorResponse, 'POST', '[NfcService] registerTagWithPrinter()');
          }
          observer.error(this.httpErrorResponse);
          observer.complete();
        })
      )))
      .subscribe((response) => {
        observer.next(response);
        observer.complete();
      });
    });
  }
}