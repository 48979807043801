import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IntuneMamService {

  private logger: Logger;

  constructor(
    private loggingService: LoggingService,
  ) {
    this.logger = loggingService.getLogger("[IntuneMamService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
   }

  public loginAndEnrollIntune(userEmail): Observable<any> {
    this.logger.info('loginAndEnrollIntune()');
    return new Observable<any>((observer: Subscriber<any>) => {
      let failure = function() {
        alert("Intune MAM login error");
      };

      let successFromSDK = function(message) {
        console.log('SDK Message', message);
        observer.next(message);
        observer.complete();
      };

      window['IntuneSupport'].loginAndEnrollAccount(userEmail, successFromSDK, failure);
    });
  }
}