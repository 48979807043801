import { Injectable } from '@angular/core';
import { Logger, LoggingService } from 'ionic-logging-service';
import { IPrinterListPagingObject } from '../printer/models/printer-list-paging.model';

@Injectable({
  providedIn: 'root'
})
export class PagingService extends IPrinterListPagingObject {

  private logger: Logger;

  constructor(
    private loggingService: LoggingService,
  ) {
    super();

    this.logger = loggingService.getLogger("[PagingService]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  public getUrlString(page: number, pageSize: number): string {
    this.logger.info('getUrlString()');
    let urlParameters =
    'page=' + page +
    "&pageSize=" + pageSize;
    return urlParameters;
  }

  public getPrinterParameters(match?): any {
    this.logger.info('getPrinterParameters()');
    let urlParameters = {
      direction: this.direction,
      sort: this.sort,
      networks: this.networks,
      embed: this.embed,
      match: match ? match : null,
      printers: this.printers,
      types: this.printerTypes,
      signIds: this.signIds
    };
    return urlParameters;
  }

  public getQueueParameters(page: number, pageSize: number, networks: Array<any>, match: string, includeQueues: Array<any>): any {
    this.logger.info('getQueueParameters()');
    let urlParameters = {
      page: page,
      pageSize: pageSize,
      sort: this.sort,
      direction: this.direction,
      includeQueues: includeQueues,
      match: match,
      networks: networks,
      printerTypes: this.printerTypes,
      embed: ['printers']
    };
    return urlParameters;
  }
}
