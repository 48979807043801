import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController, NavParams, Platform } from '@ionic/angular';
import { Logger, LoggingService } from 'ionic-logging-service';
import { OrderPipe } from 'ngx-order-pipe';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { Broadcaster } from 'src/app/services/events/broadcaster.class';
import { IntuneMamService } from 'src/app/services/intune-mam/intune-mam.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ITenantLite } from 'src/app/services/tenant/models/tenant-lite.model';
import { TenantService } from 'src/app/services/tenant/tenant.service';
import { UserService } from 'src/app/services/user/user.service';
import { PrintJobsPage } from '../../print-jobs/print-jobs.page';

@Component({
  selector: 'app-tenant-select',
  templateUrl: './tenant-select.page.html',
  styleUrls: ['./tenant-select.page.scss'],
})
export class TenantSelectPage implements OnInit {

  public clearLastUsedTenant: Boolean = null;
  public  isInternetAccessAvailable = true;
  public isInternetAvailableSubscription: Subscription = null;
  public isCordova: boolean = this.platformService.isCordova;
  private logger: Logger;
  public showDots = false;
  public tenantList: Array<ITenantLite> = null;
  public unSubscribe: Subject<boolean> = new Subject<boolean>();
  public nav: any;

  constructor(
    private broadcaster: Broadcaster,
    private changeDetectorRef: ChangeDetectorRef,
    private dialogService: DialogService,
    private loggingService: LoggingService,
    private navCtrl: NavController,
    private navParams: NavParams,
    private networkService: NetworkService,
    private platform: Platform,
    private platformService: PlatformService,
    public orderPipe: OrderPipe,
    private router: Router,
    private storageService: StorageService,
    private tenantService: TenantService,
    private userService: UserService,
    private intuneMamService: IntuneMamService
  ) {
    this.logger = loggingService.getLogger("[TenantSelectPage]");
    const methodName = "ctor";
    this.logger.entry(methodName);

    // if (this.platform.is('android')) {
    //   this.platform.backButton.subscribeWithPriority(-1, () => { // handling Android device 'back' button
    //   this.logger.info('Android backButton clicked');
    //     this.dialogService.showTranslatedMessage('SelectTenantRedirect', 'Printix');
    //   });
    // }
  }

  ngOnInit() {
    let urlTree = this.router.parseUrl(this.router.url);
    this.clearLastUsedTenant = urlTree.queryParams['clearLastUsedTenant'];
  }

  ionViewDidEnter(): void {
    console.log('## TENANT_SELECT_PAGE - ionViewDidEnter()');

    this.getTenantListForUser();
    this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
    .subscribe((isInternetAccessAvailable: boolean) => {
      this.isInternetAccessAvailable = isInternetAccessAvailable;
      this.changeDetectorRef.detectChanges();
      console.log('isInternetAccessAvailable', isInternetAccessAvailable);
    });
  }

  ionViewWillLeave(): void {
		this.isInternetAvailableSubscription.unsubscribe();
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  public getTenantListForUser(refresher?): void {
    console.log('## TENANT_SELECT_PAGE - getTenantListForUser()');
    this.logger.info('getTenantListForUser()');
    this.showDots = true;
    this.tenantService.getTenantListForUser()
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((tenantList: Array<ITenantLite>) => {
      this.storageService.addItemToKeyValueTable('numberOfTenants', tenantList.length, 'tenantSelectPage-initPage()')
      .subscribe(() => {
        this.showDots = false;
        this.tenantList = tenantList;
        if (refresher) {
          refresher.complete();
        }
      });
    }, () => {
      this.showDots = false;
    });
  }

  // public initPage(refresher?): void {
  //   console.log('## TENANT_SELECT_PAGE - initPage()');
  //   this.logger.info('initPage()');
  //   this.showDots = true;
  //       this.tenantService.getTenantListForUser()
  //       .pipe(takeUntil(this.unSubscribe))
  //       .subscribe((tenantList: Array<ITenantLite>) => {
  //         this.storageService.addItemToKeyValueTable('numberOfTenants', tenantList.length, 'tenantSelectPage-initPage()');
  //         this.showDots = false;
  //         this.tenantList = tenantList;
  //         if (refresher) {
  //           refresher.complete();
  //         }
  //       }, () => {
  //         this.showDots = false;
  //       });
  //   // this.storageService.getItemFromKeyValueTable('lastUsedTenant', 'tenantSelectPage-initPage()')
  //   // .pipe(takeUntil(this.unSubscribe))
  //   // .subscribe((tenant: ITenantLite) => {
  //   //   this.logger.info('got last used tenant:' + tenant);
  //   //   if (tenant /*&& this.checkTenantEnvironmentState(tenant)*/) {
  //   //     this.tenantSelectSuccessfull(tenant);
  //   //   } else {
  //   //   }
  //   // });
  // }

  // private checkTenantEnvironmentState(tenant: ITenantLite): boolean {
  //   this.logger.info('checkTenantEnvironmantState()');
  //   let isEnvironmentCoherent: boolean = false;
  //   let tenantUrl: Array<string> = tenant.links.self.split('.');
  //   this.environmentService.getEnvironmentEndpoint('appLink').subscribe((appLink) => {});
  //   // let environmentState: string = this.environmentService.getEnvironmentEndpoint('environmentState');
  //   let environmentState: string = 'production';

  //   switch (tenantUrl[1]) {
  //     case 'printix':
  //       if (environmentState === 'production') {
  //         isEnvironmentCoherent = true;
  //       }
  //       break;
  //       case 'devenv':
  //       if (environmentState === 'devenv') {
  //         isEnvironmentCoherent = true;
  //       }
  //       break;
  //       case 'testenv':
  //         if (environmentState === 'testenv') {
  //           isEnvironmentCoherent = true;
  //         }
  //       break;
  //   }

  //   return isEnvironmentCoherent;
  // }

  // private removeTenantFromStorage() {
  //   this.logger.info('removeTenantFromStorage()');
  //   if (this.clearLastUsedTenant) {
  //     this.storageService.getItemFromKeyValueTable('lastUsedTenant', 'removeTenantFromStorage()')
  //     .pipe(takeUntil(this.unSubscribe))
  //     .subscribe((tenant) => {
  //       this.storageService.removeItemFromKeyValueTable('lastUsedTenant')
  //       .pipe(takeUntil(this.unSubscribe))
  //       .subscribe(() => {
  //         this.initPage();
  //       });
  //     });
  //   } else {
  //     this.initPage();
  //   }
  // }

  public tenantSelectSuccessfull(tenant: ITenantLite): void {
    this.logger.info('(click) tenantSelectSuccessful()');
    this.logger.info(' Selected Tenant: ' + tenant['tenantData']['name']);

    // CLEAR TENANT AND USER, B4 GETIING THE NEW ONES; IN ORDER TO AVOID MASH-UPS IN USER_APP_SETTINGS
    this.tenantService.resetTenant();
    this.userService.resetUser();

    this.dialogService.showLoadingSpinnerDialog('tenantSelectSuccessfull()').subscribe(() => {
      this.tenantService.refreshCurrentTenant(tenant.links.self)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((tenant) => {
        this.userService.refreshCurrentUser(tenant.links.currentUser)
        .pipe(takeUntil(this.unSubscribe))
        .subscribe((user) => {
          this.storageService.addItemToKeyValueTable('lastUsedTenant', tenant, 'tenantSelectSuccessfull()')
          .subscribe(() => {
            this.dialogService.hideLoadingSpinnerDialog('TenantSelectPage - tenantSelectSuccessfull()');
            this.checkForIntunePolicy(user); // before going to printjobs page, check if Intune MAM policy is set by customer
          });
        });
      });
    });
  }

  private checkForIntunePolicy(user) {
    const methodName = 'checkForIntunePolicy() '
    this.logger.info(methodName);
    // if (true) { // tenant.tenantData.intune // checking for Intune flag, to determine if user needs to verify against Intune MAM policy
    //   const userEmail = user.embedded ? user.embedded.user.email : null;
    //   this.logger.info(methodName + 'loginAndEnrollIntune with userEmail: ' + userEmail);
    //   if (userEmail) {
      //     this.intuneMamService.loginAndEnrollIntune(userEmail)
      //     .takeUntil(this.unSubscribe)
      //     .subscribe((response) => {
      //   this.logger.info(methodName + 'loginAndEnrollIntune response: ' + response);
      //       this.unSubscribe.next();
      //       this.unSubscribe.complete();
      //       this.navController.setRoot(PrintJobsPage).then(() => {});
      //     });
      //   } else {
      //   this.logger.info(methodName + 'cannot loginAndEnrollIntune missing userEmail');
    //     this.dialogService.showAlertDialog('[TenantSelect] Cannot connect to Intune MAM, missing userEmail: ' + userEmail);
    //   }
    // } else {
    //   this.navController.setRoot(PrintJobsPage).then(() => {});
    // }
    this.navCtrl.navigateRoot('/print-jobs');
  }
}
