import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'printjobbyprinter'
})
export class PrintJobByPrinterPipe implements PipeTransform {

  transform(items: any[], field: Object): any[] {
    if (!items) return [];
    return items.filter(it => it.links.printer.indexOf(field) !== -1);
  }
}
