<ion-card class="http-error-modal" [ngClass]="{'internal-error' : hasInternalError}" text-center no-padding-vertical *ngIf="showHttpErrorModal">
  <ion-card-header>
      <ion-icon class="icon-warning" name="wifi"></ion-icon>
      <span class="error-modal-title server-error" *ngIf="hasServerError">
          {{"ServerError" | translate}}
      </span>
      <span class="error-modal-title server-error" *ngIf="requestError">
          {{"UpsSomethingWentWrong" | translate}}
          <span class="try-again">{{"TryAgain" | translate}}</span>
      </span>
      <span class="error-modal-title server-error" *ngIf="hasInternalError">
          {{"InternalError" | translate}}
      </span>
      <span class="error-modal-title server-error" *ngIf="requestTimedOut">
          {{"ConnectionFailed" | translate}}
      </span>
      <ion-icon class="icon-close" tappable (click)="dismiss(null)"></ion-icon>
  </ion-card-header>
  <ion-card-content *ngIf="!requestTimedOut" #errorMessageContent>
      <div class="error-message-content-inner">
          <div class="mb10" *ngIf="requestMethod">
              <span class="error-message-content-title">Http method:</span>
              <span class="error-message-content-text">{{requestMethod}}</span>
          </div>
          <div *ngIf="httpErrorCode">
              <span class="error-message-content-title">Error Code:</span>
              <ion-input class="error-message-content-text" type="text" value="{{httpErrorCode}}"></ion-input>
          </div>
          <div *ngIf="httpErrorText">
              <span class="error-message-content-title">Error Text:</span>
              <ion-input class="error-message-content-text" type="text" value="{{httpErrorText}}"></ion-input>
          </div>
          <div *ngIf="httpErrorMessage">
              <span class="error-message-content-title">Error Message:</span>
              <ion-textarea class="error-message-content-text" type="url" rows="5" value="{{httpErrorMessage}}"></ion-textarea>
          </div>
          <div class="mb10" *ngIf="httpErrorStatus">
              <span class="error-message-content-title">Error status:</span>
              <span class="error-message-content-text">{{httpErrorStatus}}</span>
          </div>
          <div *ngIf="httpRequestId">
              <span class="error-message-content-title">Error request ID:</span>
              <ion-input class="error-message-content-text" type="url" value="{{httpRequestId}}"></ion-input>
          </div>
          <div *ngIf="requestUrl">
              <span class="error-message-content-title">Http end point:</span>
              <ion-textarea class="error-message-content-text" type="url" rows="5" value="{{requestUrl}}"></ion-textarea>
          </div>
      </div>
  </ion-card-content>
</ion-card>
