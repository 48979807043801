import { Component, OnInit } from '@angular/core';
import { NavController, Platform, PopoverController, } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LoginService } from 'src/app/services/login/login.service';
import { LogoutService } from 'src/app/services/logout/logout.service';
import { NetworkService } from 'src/app/services/network/network.service';
import { PlatformService } from 'src/app/services/platform/platform.service';
import { StorageService } from 'src/app/services/storage/storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Logger, LoggingService } from 'ionic-logging-service';
import { EnvironmentService } from 'src/app/services/environment/environment.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-network-check',
  templateUrl: './network-check.page.html',
  styleUrls: ['./network-check.page.scss'],
})
export class NetworkCheckPage implements OnInit {
  public unSubscribe: Subject<boolean> = new Subject<boolean>();
  public isInternetAvailableSubscription: Subscription = null;
  public isInternetAccessAvailable = true;
  public appLogo: string = 'assets/img/logo/logo-img.png';
  public isCordova: boolean = this.platformService.isCordova;
  public errorMessage: string = null;
  public showUnsupportedVersionMessage: boolean = false;
  public APP_ID: string = 'id1391512097';
  public APP_PACKAGE_NAME: string = 'net.printix.printixapp';
  public EULA_ACCEPTED_STORAGE_STRING = 'eula-accepted';
  public EULA_ACCEPTED_STORAGE_KEY = 'is-eula-accepted';
  public showEulaText = false;
  public deviceVersion: object = null;
  public forceBefore: object = null;
  public warnBefore: object = null;
  private queryParams: any;
  private logger: Logger;

  private endpointLogInWepAppTenant: string;
  private endpointLogInResponseForgotCode_WepApp: string;
  private endpointLogInResponse_WepApp: string;
  public eulaHtml: string;

  constructor (
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private loggingService: LoggingService,
    private loginService: LoginService,
    private logoutService: LogoutService,
    public navController: NavController,
    private networkService: NetworkService,
    public platform: Platform,
    public popoverCtrl: PopoverController,
    private platformService: PlatformService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private httpClient: HttpClient
  ) {
    this.logger = loggingService.getLogger("[NetworkCheckPage]");
    const methodName = "ctor";
    this.logger.entry(methodName);
  }

  ngOnInit() {
    console.log('## ngOnInit()');
    let urlTree = this.router.parseUrl(this.router.url);
    this.queryParams = urlTree.queryParams;
    if (this.isCordova) {
      // check for new Install meaning: does localstorage have a 'EULA accepted' string
      // if not: show EULA to user
      // upon accept: store EULA accepted string in localstorage and continue in the app
      // upon deny: stop all app activities
      this.checkForAcceptedEula();
    } else {
      this.setListeners();
    }
  }

  private checkForAcceptedEula(): void {
    this.storageService.getItemFromKeyValueTable(this.EULA_ACCEPTED_STORAGE_KEY)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe((EULA_ACCEPTED) => {
      if (EULA_ACCEPTED === this.EULA_ACCEPTED_STORAGE_STRING) {
        this.setListeners();
      } else {
        console.log('## SHOW_EULA_TEXT ##');
        this.showEulaText = true;
        this.loadEulaText();
      }
      this.unSubscribe.next();
      this.unSubscribe.complete();
    });
  }

  loadEulaText() {
    this.httpClient.get('assets/eula-text/eula.html', { responseType: 'text' }).subscribe(html => {
      this.eulaHtml = html;
    });
  }

  ionViewWillLeave(): void {
		this.isInternetAvailableSubscription.unsubscribe();
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }

  public eulaAccepted() {
    this.storageService.addItemToKeyValueTable(this.EULA_ACCEPTED_STORAGE_KEY, this.EULA_ACCEPTED_STORAGE_STRING)
    .pipe(takeUntil(this.unSubscribe))
    .subscribe(() => {
      this.showEulaText = false;
      this.setListeners();
    })
  }

  public eulaDenied() {
    this.showEulaText = false;
    navigator['app'].exitApp();
  }

  private setListeners(): void {
    this.logger.info('setListeners()');

    this.route.queryParams
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(params => {
        if (params['URL_PARAM_LOGOUT'] === "true") {
          this.startLogOut();
        } else if (params['URL_PARAM_UNAUTHENTICATED_USER'] === "true") {
          this.logger.info('URL_PARAM_UNAUTHENTICATED_USER');
          this.handleUnverifiedCredentials();
          // this.startUnAuthenticatedLogOut();
        } else {
          // GET stored environment value
          if (this.isCordova) {
            this.environmentService.setEnvironmentPrefixFromStorage().subscribe((environmentPrefix) => {
              // this.checkAppVersionState();
              // outcommented call to checkAppVersionState(), since it has not been used for years
              // and it needs a reworking to accomodate multiple datacenters, both for the endpoint and
              // also not to be checked so early in the app, before the currentEnvironmentEndpoints are set.
              this.verifyUserCredentials();
            });
          } else {
            let url = location.href;
            this.environmentService.setEnvironmentVariablesFromUrl(url).subscribe((response) => {
              // this.checkAppVersionState();
              // outcommented call to checkAppVersionState(), since it has not been used for years
              // and it needs a reworking to accomodate multiple datacenters, both for the endpoint and
              // also not to be checked so early in the app, before the currentEnvironmentEndpoints are set.
              this.verifyUserCredentials();
            });
          }
        }
      });

      this.isInternetAvailableSubscription = this.networkService.isInternetAvailable
	    .subscribe((isInternetAccessAvailable: boolean) => {
  			this.isInternetAccessAvailable = isInternetAccessAvailable;
  			// console.log('isInternetAccessAvailable', isInternetAccessAvailable);
        if (isInternetAccessAvailable) {
          // this.verifyUserCredentials();
        }
	    });
    }

  // public checkAppVersionState(): void {
  //   this.logger.info('checkAppVersionState()');
  //   if (this.isInternetAccessAvailable) {
  //     if (this.isCordova) {
  //       this.appEndpoint = this.environmentService.getEnvironmentEndpoint['appEndpoint'];
  //       this.versionControlService.getCurrentAppVersion(this.appEndpoint)
  //       .pipe(takeUntil(this.unSubscribe))
  //       .subscribe((currentVersionResource: object) => {
  //         this.forceBefore = currentVersionResource['forceBefore'];
  //         this.warnBefore = currentVersionResource['warnBefore'];
  //         this.unSubscribe.next();
  //         this.unSubscribe.complete();

  //         this.deviceVersion = DEVICE_APP_VERSION['version'];
  //         this.logger.info('checkAppVersionState() AppVersion: ' + this.deviceVersion['major'] + '.' + this.deviceVersion['minor'] + '.' + this.deviceVersion['patch']);

  //         this.handleAppVersionState(this.deviceVersion, this.forceBefore, this.warnBefore);
  //       });
  //     } else {
  //       this.verifyUserCredentials();
  //     }
  //       // this.verifyUserCredentials(); // for when accessing dev, since on dev you cannot get currentAppVersion
  //   }
  // }

  // ######################### VERSION CONTROL ############################################### //
  // #######                                                                           ####### //
  // #######  The app will check the current released app version from the online      ####### //
  // #######  resource (webapp) in the file:
  // #######  - 'src/assets/json/release-app-version.json' -                           ####### //
  // #######  against the local app version on the users device in the file:           ####### //
  // #######  - 'src/providers/services/version-control/deviceAppVersion.ts' -         ####### //
  // #######  if the warn/force before parameters are higher than the current version  ####### //
  // #######  on the users device, the user will be promted with a warning or          ####### //
  // #######  requirement of an update of the app, with a link to Google/Apple store   ####### //
  // #######  according the users device                                               ####### //
  // #######                                                                           ####### //
  // ######################################################################################### //

  // private async handleAppVersionState(deviceVersion: object, forceBefore: object, warnBefore: object): Promise<void> {
  //   this.logger.info('handleAppVersionState()');
  //   if (this.deviceVersion['major'] < this.forceBefore['major'] ||
  //       this.deviceVersion['minor'] < this.forceBefore['minor'] ||
  //       this.deviceVersion['patch'] < this.forceBefore['patch'] ||
  //       this.deviceVersion['revision'] < this.forceBefore['revision']
  //   ) {

  //     this.showUnsupportedVersionMessage = true; // stop app with update required message and link to app/play store

  //   } else if (this.deviceVersion['major'] < this.warnBefore['major'] ||
  //              this.deviceVersion['minor'] < this.warnBefore['minor'] ||
  //              this.deviceVersion['patch'] < this.warnBefore['patch'] ||
  //              this.deviceVersion['revision'] < this.warnBefore['revision']
  //   ) {
  //     let dataPackage: any = {
  //       type: 'deviceVersionWarning',
  //       cssClass: 'device-version-warning'
  //     };

  //     let popover = await this.popoverCtrl.create({
  //       component: PopoverComponent,
  //       componentProps: {dataPackage},
  //       cssClass: dataPackage.cssClass
  //     });

  //     await popover.present(); // Show popup with notification about new app in app/play store

  //     await popover.onDidDismiss()
  //     .then((popoverData) => {
  //       if (popoverData.data === 'cancel') {
  //         this.verifyUserCredentials();
  //       } else if ('goToStore') {
  //         this.goToStore();
  //       }
  //     });

  //   } else {
  //     this.verifyUserCredentials();
  //   }
  // }

  private verifyUserCredentials(): void {
    const methodName = 'verifyUserCredentials() '
    this.logger.info(methodName);
    if (location.hash.includes('refreshToken=')) {
      this.logger.info(methodName + 'url includes "refreshToken="');
      this.logInFromTenantHub();
    } else {
      this.logger.info(methodName + 'url does NOT include "refreshToken="');
      this.authService.verifyUserCredentials()
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(() => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.loginService.authSuccessful();
      }, () => {
        this.unSubscribe.next();
        this.unSubscribe.complete();
        this.handleUnverifiedCredentials();
      });
    }
  }

  private handleUnverifiedCredentials() {
    const methodName = 'handleUnverifiedCredentials() '
    this.logger.info(methodName);
    if (!location.search.includes('code=')) {
      this.logger.info(methodName + 'url does not contain "code="');
      // console.log('**** NetworkCheck - L210 - location.search', location.search);
      let urlTenantDomain: string = location.href.split('/')[2];
      let signInAppUrl: string;

      if (!this.isCordova && !urlTenantDomain.includes('localhost')) { // Webapp, not in development
      // if (!this.isCordova) { // Webapp, to get to a tenant when in development
        // GO TO LOGIN PAGE
        this.endpointLogInWepAppTenant = this.environmentService.getEnvironmentEndpoint['endpointLogInWepAppTenant']
        this.endpointLogInResponseForgotCode_WepApp = this.environmentService.getEnvironmentEndpoint['endpointLogInResponseForgotCode_WepApp']
        this.endpointLogInResponse_WepApp = this.environmentService.getEnvironmentEndpoint['endpointLogInResponse_WepApp']
        if (location.search.includes('registrationCode')) {
          urlTenantDomain = this.handleRegistrationCodeInUrl(urlTenantDomain);
          signInAppUrl = this.endpointLogInWepAppTenant + urlTenantDomain + this.endpointLogInResponseForgotCode_WepApp + urlTenantDomain;
        } else {
          signInAppUrl = this.endpointLogInWepAppTenant + urlTenantDomain + this.endpointLogInResponse_WepApp + urlTenantDomain;
        }
        location.search = '';
        window.open(signInAppUrl, '_self');

        // TEST-URL for testing registrationCode functionality in regard to Printix GO and on-device
        // let alternateSignInAppUrl = this.endpointLogInWepAppTenant + 'printix70.printix.net?registrationCode=FORGOT_CODE' + this.endpointLogInResponseForgotCode_WepApp + 'printix70.printix.net?registrationCode=FORGOT_CODE';
        // let alternateSignInAppUrl = this.endpointLogInWepAppTenant + 'printix29.devenv.printix.net' + this.endpointLogInResponse_WepApp + 'printix29.devenv.printix.net';
        // window.open(alternateSignInAppUrl, '_self');
      } else {
        this.logger.info(methodName + 'Go to login page');
        this.navController.navigateRoot(['/sign-in'], {queryParams: this.queryParams}).then(() => {});
      }
    } else {
      if (!this.isCordova) { // has 'code' in url, continue logging in
        this.loginService.handleOpenUrl(location.href);
      }
    }
  }

  private handleRegistrationCodeInUrl(urlTenantDomain) {
    // IF registrationCode is somewhere to be found in the queryParams, then execute the block below
    // This block will try to set registrationCode on the redirect_uri regardsless of where in the
    // query param registrationCode is found.
    // If registrationCode is missing the '?' because it was last, then it will try to set the '?'

    console.log('location.href', location.href);
    console.log('location.search', location.search);
    // console.log('urlTenantDomain', urlTenantDomain);

    if (!location.search.includes('&')) {
      urlTenantDomain = urlTenantDomain + location.search;
    } else if (location.search.includes('&')) {
      let params: any = location.search;
      params = params.split('&');
      for (let i = 0; i < params.length; i++) {
        if (params[i].includes('registrationCode')) {
          let registrationCode = params[i];
          if (!registrationCode.includes('?')) {
            registrationCode = '?' + registrationCode;
            urlTenantDomain = urlTenantDomain + registrationCode;
          } else {
            urlTenantDomain = urlTenantDomain + registrationCode;
          }
        }
      }
    }
    return urlTenantDomain;
  }

  private logInFromTenantHub() {
    this.logger.info('logInFromTenantHub()');
    let refreshToken = location.hash.split('=')[1];
    this.authService.refreshAccessTokenFromUrl(refreshToken)
    .subscribe(() => {
      location.hash = '';
      this.loginService.authSuccessful();
    });
  }

  // public goToStore(): void {
  //   this.logger.info('goToStore() - To update app');
  //   if (this.platform.is('android')) {
  //     this.market.open(this.APP_PACKAGE_NAME);
  //   } else if (this.platform.is('ios') || this.platform.is('ipad')) {
  //     this.market.open(this.APP_ID);
  //   }
  // }

  // private startUnAuthenticatedLogOut() {
  //   this.logger.info('startUnAuthenticatedLogOut()');
  //   this.logoutService.logOutUnAuthenticatedUser();
  // }

  public startLogOut() {
    this.logger.info('startLogOut()');
    // Remove query params
    this.router.navigate([], {
      queryParams: {
        'URL_PARAM_LOGOUT': null
      },
      queryParamsHandling: 'merge'
    });
    this.logoutService.logOut();
  }
}