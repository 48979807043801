import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';


import { NetworkService } from './network.service';
import { Observable, Subject } from 'rxjs';
// import { stringify } from '@angular/compiler/src/util';
import { Platform } from '@ionic/angular';
import { tap } from 'rxjs/operators';
import { Logger, LoggingService } from 'ionic-logging-service';

// export const MESSAGE_ERROR_NO_INTERNET_ACCESS: string = 'MESSAGE_ERROR_NO_INTERNET_ACCESS';

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {

  private isInternetAccessAvailable: boolean = true;
  private unSubscribe: Subject<boolean> = new Subject<boolean>();
  private logger: Logger;

  constructor(
    private injector: Injector,
    private platform: Platform,
    private loggingService: LoggingService,

  ) {
    this.platform.ready().then(() => {
      this.logger = loggingService.getLogger("[NetworkInterceptor]");
      const methodName = "ctor";
      this.logger.entry(methodName);
    //   const networkService: NetworkService = this.injector.get(NetworkService);
    //   networkService.isInternetAccessAvailable
    //   .takeUntil(this.unSubscribe)
    //   .subscribe((isInternetAccessAvailable: boolean) => {
    //     this.isInternetAccessAvailable = isInternetAccessAvailable;
    //     this.unSubscribe.next();
    //     this.unSubscribe.complete();
    //   });
    });
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('ALL httpRequests: ', httpRequest);
    // this.logger.info('[NETWORK_INTERCEPTOR] REQUEST '  + JSON.stringify(httpRequest));
    return next.handle(httpRequest)
    .pipe(tap(httpResponse => {
      // this.logger.info('[NETWORK_INTERCEPTOR] RESPONSE ' + JSON.stringify(httpResponse));
      // console.log([NETWORK_INTERCEPTOR] RESPONSE, httpResponse);
    }, (httpErrorResponse: HttpErrorResponse) => {
      // this.logger.info('[NETWORK_INTERCEPTOR] ERROR_RESPONSE ' + JSON.stringify(httpErrorResponse));
      // console.log('[NETWORK_INTERCEPTOR] ERROR_RESPONSE', httpErrorResponse);
    }));
  }
}
